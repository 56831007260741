import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import s from './ScheduledBadge.scss';
import NotificationsFillSmallIcon from 'wix-ui-icons-common/on-stage/NotificationsFillSmall';
import PlusSmallIcon from 'wix-ui-icons-common/on-stage/PlusSmall';
import { MILLISECONDS } from '@wix/wix-vod-shared/common';
import { useOpenGoogleCalendar } from '../../../hooks/useOpenGoogleCalendar';

const useCountdown = (startTime: number) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const timeLeft = startTime - currentTime;
  const numberFormat = Intl.NumberFormat('en', { minimumIntegerDigits: 2 });
  let hours = '00';
  let minutes = '00';
  let seconds = '00';

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (timeLeft > 0) {
    hours = numberFormat.format(Math.floor(timeLeft / MILLISECONDS.ONE_HOUR));
    minutes = numberFormat.format(
      Math.floor((timeLeft % MILLISECONDS.ONE_HOUR) / MILLISECONDS.ONE_MINUTE),
    );
    seconds = numberFormat.format(
      Math.floor(
        (timeLeft % MILLISECONDS.ONE_MINUTE) / MILLISECONDS.ONE_SECOND,
      ),
    );
  }

  return {
    hours,
    minutes,
    seconds,
  };
};

const Countdown: FC<{
  startTime: number;
}> = ({ startTime }) => {
  const { t } = useTranslation();
  const { hours, minutes, seconds } = useCountdown(startTime);
  const dateTime = `PT${hours}H${minutes}M${seconds}S`;

  return (
    <span aria-label={t('widget.accessibility.scheduled-soon-label')}>
      <span className={s.date} aria-label={t('widget.accessibility.starts-in')}>
        <time dateTime={dateTime}>{`${hours} : ${minutes} : ${seconds}`}</time>
      </span>
    </span>
  );
};

const StartDate: FC<{
  startDate: string;
}> = ({ startDate }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formattedDate = useMemo(
    () =>
      Intl.DateTimeFormat(language, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(startDate)),
    [language, startDate],
  );

  return (
    <span
      className={s.date}
      aria-label={t('widget.accessibility.scheduled-label')}
    >
      <time dateTime={formattedDate}>{formattedDate}</time>
    </span>
  );
};

export const ScheduledBadge: FC<{
  startDate: string;
  title: string;
}> = ({ startDate, title }) => {
  const { t } = useTranslation();
  const date = new Date(startDate);
  const startTime = date.getTime();
  const startsSoon = startTime - Date.now() < MILLISECONDS.ONE_DAY;
  const handleReminderClick = useOpenGoogleCalendar(date, title);

  return (
    <div className={s.root}>
      <div className={s.text}>
        <span>{t('shared.live')}:</span>
        {startsSoon ? (
          <Countdown startTime={startTime} />
        ) : (
          <StartDate startDate={startDate} />
        )}
      </div>
      <div className={s.icon} aria-hidden="true">
        <PlusSmallIcon width={12} height={12} />
        <NotificationsFillSmallIcon />
      </div>
      <button
        onClick={handleReminderClick}
        className={s.button}
        aria-label={t('widget.accessibility.reminder')}
      />
    </div>
  );
};
