import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Dropdown, DropdownOptionProps } from 'wix-ui-tpa';
import { ShareMode } from './ShareMode';
import { classes } from './DarkShareSelectMode.st.css';

export const DarkShareSelectMode: FC<{
  mode: ShareMode;
  onChangeMode: (mode: ShareMode) => void;
}> = ({ mode, onChangeMode }) => {
  const { t } = useTranslation();
  const options: DropdownOptionProps[] = [
    { id: 'channel', value: t('share-overlay.social.share-channel') },
    { id: 'video', value: t('share-overlay.social.share-video') },
  ];
  return (
    <Dropdown
      className={classes.root}
      options={options}
      initialSelectedId={mode}
      onChange={(selectedOption) =>
        onChangeMode(selectedOption.id as ShareMode)
      }
      data-hook="dark-share-select-mode"
    />
  );
};
