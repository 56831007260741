import React, { FC } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import {
  useChannel,
  useChannelAccessButton,
  useChannelPlayButton,
  usePlayback,
} from '../../../../hooks';
import stylesParams from '../../../../../../stylesParams';
import { Overlay, OverlayHeader, OverlayHeaderTitle } from '../Overlay/Overlay';

const useViewSettings = () => {
  const styles = useStyles();
  const showTitle = styles.get(stylesParams.showTitleForMainItem);

  return { showTitle };
};

export const ChannelOverlay: FC = () => {
  const channelPlayButton = useChannelPlayButton();
  const channelAccessButton = useChannelAccessButton();
  const channel = useChannel();
  const { showTitle } = useViewSettings();
  const { play } = usePlayback();
  const playButton = channelPlayButton.canPlay
    ? {
        text: channelPlayButton.text,
        handler: play,
      }
    : undefined;
  const videoUrl = channel.play.entity.url;

  return (
    <Overlay
      playButton={playButton}
      paidButton={channelAccessButton}
      coverUrl={channel.cover.selected}
      videoUrl={videoUrl}
      ariaLabel={channel.title}
      content={
        showTitle ? (
          <OverlayHeader playButton={playButton}>
            <OverlayHeaderTitle>{channel.title}</OverlayHeaderTitle>
          </OverlayHeader>
        ) : null
      }
    />
  );
};
