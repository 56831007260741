import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './VideoAspectRatio.scss';

export enum VideoAspectRatioMode {
  FIT_TO_HEIGHT = 'FIT_TO_HEIGHT',
  FIT_TO_WIDTH = 'FIT_TO_WIDTH',
}

const stylesMap = {
  [VideoAspectRatioMode.FIT_TO_HEIGHT]: styles.fitToheight,
  [VideoAspectRatioMode.FIT_TO_WIDTH]: styles.fitToWidth,
};

const image16x9 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAGklEQVQYlWP8//8/AymAiSTVoxpoooGBgQEAMWADDx3noXgAAAAASUVORK5CYII=';

type Props = {
  mode?: VideoAspectRatioMode;
};

export const VideoAspectRatio: FC<Props> = ({
  children,
  mode = VideoAspectRatioMode.FIT_TO_WIDTH,
}) => {
  return (
    <div className={styles.container}>
      <img
        className={classnames(styles.ratioImage, stylesMap[mode])}
        src={image16x9}
        alt=""
        aria-hidden="true"
      />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
