import { useStyles } from '@wix/tpa-settings/react';
import React, { FC, useCallback, useState } from 'react';
import { PlaybackContext } from '../../hooks/usePlayback';
import stylesParams from '../../../../stylesParams';

type PlaybackStatus = 'initial' | 'playing' | 'paused' | 'ended';

const usePlaybackStatus = (shouldAutoplay: boolean) => {
  const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus>(
    shouldAutoplay ? 'playing' : 'initial',
  );

  const setPlaying = useCallback(
    () => setPlaybackStatus('playing'),
    [setPlaybackStatus],
  );
  const setPaused = useCallback(
    () => setPlaybackStatus('paused'),
    [setPlaybackStatus],
  );
  const setInitial = useCallback(
    () => setPlaybackStatus('initial'),
    [setPlaybackStatus],
  );
  const setEnded = useCallback(
    () => setPlaybackStatus('ended'),
    [setPlaybackStatus],
  );

  return {
    playing: playbackStatus === 'playing',
    paused: playbackStatus === 'paused',
    ended: playbackStatus === 'ended',
    setPlaying,
    setPaused,
    setInitial,
    setEnded,
  };
};

export const PlaybackProvider: FC = ({ children }) => {
  const styles = useStyles();
  const shouldAutoplay = styles.get(stylesParams.autoPlay);
  const [muted, setMuted] = useState(shouldAutoplay);
  const {
    playing,
    paused,
    ended,
    setPlaying,
    setPaused,
    setEnded,
    setInitial,
  } = usePlaybackStatus(shouldAutoplay);

  const play = useCallback(() => {
    setMuted(false);
    setPlaying();
  }, [setPlaying]);

  return (
    <PlaybackContext.Provider
      value={{
        playing,
        play,
        pause: setPaused,
        paused,
        muted,
        ended,
        setMuted,
        setEnded,
        setInitial,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  );
};
