import React, { FC } from 'react';
import {
  Picture,
  PictureMode,
} from '../../../common/components/Picture/Picture';
import styles from './PlayerOverlayVideoInfoPoster.scss';
import { usePlayableFullScreenManager } from '../../../common/components/PlayableFullScreenManager/PlayableFullScreenManager';

const POSTER_SIZE = {
  width: 303,
  height: 449,
};

const POSTER_SIZE_FULLSCREEN = {
  width: 450,
  height: 667,
};

export const PlayerOverlayVideoInfoPoster: FC<{
  posterUrl?: string;
}> = ({ posterUrl }) => {
  const { isFullScreen } = usePlayableFullScreenManager();
  if (!posterUrl) {
    return null;
  }
  return (
    <Picture
      className={styles.videoPoster}
      breakpoints={[
        {
          min: 0,
          ...(isFullScreen ? POSTER_SIZE_FULLSCREEN : POSTER_SIZE),
        },
      ]}
      src={posterUrl}
      mode={PictureMode.COVER}
    />
  );
};
