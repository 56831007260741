import classNames from 'classnames';
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { PlaybackComponentProps } from '../../Player.types';
import s from './Facebook.scss';
import { FacebookPlayer } from './Facebook.types';

const win = typeof window !== 'undefined' ? (window as any) : undefined;

const apiPromise = new Promise<void>((resolve) => {
  if (win && !win.FB) {
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
    document.body.appendChild(script);
    const originalFbAsyncInit = win.fbAsyncInit;
    win.fbAsyncInit = () => {
      if (originalFbAsyncInit) {
        originalFbAsyncInit();
      }
      resolve();
    };
  }
});

type Size = {
  width: number;
  height: number;
};

const PlayerAPI: FC<
  PlaybackComponentProps & {
    player: FacebookPlayer;
  }
> = (props) => {
  const { player, playing, muted } = props;

  useLayoutEffect(() => {
    muted ? player.mute() : player.unmute();
  }, [player, muted]);

  useLayoutEffect(() => {
    playing ? player.play() : player.pause();
  }, [player, playing]);

  return null;
};

const Player: FC<
  PlaybackComponentProps & {
    size: Size;
  }
> = (props) => {
  const { size, src, onEnd, onPlay, onPause } = props;
  const [player, setPlayer] = useState<FacebookPlayer>();
  const ref = useRef<HTMLDivElement>(null);

  const handleReady = useCallback(
    (message: any) => {
      if (message.type === 'video' && message.id === src) {
        const instance: FacebookPlayer = message.instance;
        setPlayer(instance);

        onPlay && instance.subscribe('startedPlaying', onPlay);
        onPause && instance.subscribe('paused', onPause);
        onEnd && instance.subscribe('finishedPlaying', onEnd);
      }
    },
    [src, onPlay, onPause, onEnd],
  );

  const setAllowAttribute = useCallback(() => {
    const iframe = ref.current?.querySelector('iframe');

    if (!iframe) {
      return;
    }

    const allows = iframe.allow.split(' ').map((i) => i.trim());

    if (!allows.includes('autoplay')) {
      allows.push('autoplay');
      iframe.setAttribute('allow', allows.join(';'));
      iframe.setAttribute('src', iframe.src);
    }
  }, []);

  useEffect(() => {
    win.FB.init({
      xfbml: true,
      version: 'v3.2',
    });
  }, []);

  useEffect(() => {
    win.FB.Event.subscribe('xfbml.ready', handleReady);
    win.FB.Event.subscribe('iframeplugin:create', setAllowAttribute);

    return () => {
      win.FB.Event.unsubscribe('xfbml.ready', handleReady);
      win.FB.Event.unsubscribe('iframeplugin:create', setAllowAttribute);
    };
  }, [handleReady, setAllowAttribute]);

  return (
    <>
      <div
        className={classNames('fb-video', s.video)}
        id={src}
        ref={ref}
        data-href={src}
        data-height={size.height}
        data-width={size.width}
        data-show-text="false"
        data-allowfullscreen="true"
      />
      {player ? <PlayerAPI {...props} player={player} /> : null}
    </>
  );
};

export const Facebook: FC<PlaybackComponentProps> = (props) => {
  const [size, setSize] = useState<Size | null>(null);
  const [apiLoaded, setApiLoaded] = useState(false);

  const setContainerRef = useCallback((div: HTMLDivElement | null) => {
    if (!div) {
      return;
    }

    const height = Math.ceil(div.clientHeight);
    const width = Math.ceil((height / 9) * 16);

    setSize({ width, height });
  }, []);

  useEffect(() => {
    apiPromise.then(() => setApiLoaded(true));
  }, []);

  if (!apiLoaded) {
    return null;
  }

  return (
    <div ref={setContainerRef} className={s.facebook}>
      {size ? <Player {...props} size={size} key={props.src} /> : null}
    </div>
  );
};
