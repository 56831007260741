import React, { FC } from 'react';
import { widgetSignInClicked } from '@wix/bi-logger-video/v2';
import { TextButtonPriority } from 'wix-ui-tpa';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useWorker } from '../../../hooks/useWorker';
import { TextButton } from '../../../common/components/TextButton/TextButton';
import { useChannelId } from '../../../hooks';

const useSignIn = () => {
  const bi = useBi();
  const channelId = useChannelId();
  const { user } = useWorker();
  const { language } = useEnvironment();
  const { openSignIn } = user;
  return {
    openSignIn: async () => {
      bi.report(
        widgetSignInClicked({
          channelID: channelId,
        }),
      );
      await openSignIn({ mode: 'login', language });
    },
  };
};

export const SignInButton: FC = () => {
  const { t } = useTranslation();

  const { openSignIn } = useSignIn();

  return (
    <TextButton
      onClick={openSignIn}
      priority={TextButtonPriority.primary}
      data-hook="sign-in-button"
    >
      {t('widget.account.sign-in')}
    </TextButton>
  );
};
