import React, { FC } from 'react';
import { useGetChannelQuery } from '../../apollo/generated/graphql';
import { useChannelId } from '../../hooks';
import { ChannelContext } from '../../hooks/useChannel';

export const ChannelProvider: FC = ({ children }) => {
  const channelId = useChannelId();
  const { data } = useGetChannelQuery({
    variables: {
      id: channelId,
    },
  });
  const channel = data?.channel;

  return (
    <ChannelContext.Provider value={channel}>
      {children}
    </ChannelContext.Provider>
  );
};
