import React, { FC } from 'react';
import { DarkShareSocialButton } from './DarkShareSocialButton';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ShareMode } from './ShareMode';
import { useShare } from './useShare';
import TwitterIcon from 'wix-ui-icons-common/on-stage/Twitter';
import { useSiteInfo } from '../../../common/providers/SiteInfoProvider';
import { useSelectedVideo } from '../../../hooks';

const BASE_TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';

const useOriginalReferrerUrl = (mode: ShareMode) => {
  const { siteUrl, compId } = useSiteInfo();
  const { selectedVideo } = useSelectedVideo();
  const originalReferrerUrl = new URL(siteUrl);
  originalReferrerUrl.searchParams.set('wix-vod-comp-id', compId);
  if (mode === 'video') {
    originalReferrerUrl.searchParams.set('wix-vod-video-id', selectedVideo.id);
  }
  return originalReferrerUrl.href;
};

const useShareToTwitter = (mode: ShareMode) => {
  const { t } = useTranslation();
  const originalReferrer = useOriginalReferrerUrl(mode);
  return useShare(
    'twitter',
    mode,
    ({ mediaShareUrl: shareUrl, shareContent }) => {
      const descriptionText = t('social-share.network.twitter', {
        title: shareContent.title,
        siteUrl: shareUrl,
      });
      const twitterUrl = new URL(BASE_TWITTER_SHARE_URL);
      twitterUrl.searchParams.set('original_referer', originalReferrer);
      twitterUrl.searchParams.set('text', descriptionText);
      return twitterUrl.href;
    },
  );
};

export const DarkShareTwitter: FC<{
  mode: ShareMode;
}> = ({ mode }) => {
  const { t } = useTranslation();
  const shareToTwitter = useShareToTwitter(mode);

  return (
    <DarkShareSocialButton
      Icon={TwitterIcon}
      onClick={shareToTwitter}
      tooltipText={t('social-button.twitter')}
      ariaLabel={t('widget.accessibility.share-on', {
        network: 'Twitter',
      })}
      dataHook="dark-share-twitter"
    />
  );
};
