import React, {
  FC,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './Header.scss';
import { InfoButton } from '../../../../common/components/InfoButton/InfoButton';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { useChannel, useChannelAccessButton } from '../../../../hooks';
import { TextButtonPriority, Tooltip } from 'wix-ui-tpa';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../stylesParams';
import { HeaderAccountInfo } from '../../HeaderAccountInfo/HeaderAccountInfo';

const useHeaderSettings = () => {
  const styles = useStyles();
  const showTitle = styles.get(stylesParams.showChannelTitle);
  const showChannelShare = styles.get(stylesParams.showChannelShare);
  const showChannelInfo = styles.get(stylesParams.showChannelInfo);
  const showSignIn = styles.get(stylesParams.showSignIn);
  return { showTitle, showChannelShare, showChannelInfo, showSignIn };
};

const ChannelInfoButton: FC = () => <InfoButton />;

const ChannelAccessButton: FC = () => {
  const channelAccessButton = useChannelAccessButton();
  if (!channelAccessButton) {
    return null;
  }
  return (
    <TextButton
      onClick={() => channelAccessButton.handler()}
      priority={TextButtonPriority.secondary}
    >
      {channelAccessButton.text}
    </TextButton>
  );
};

const ButtonWrapper: FC = ({ children }) => (
  <div className={styles.buttonWrapper}>{children}</div>
);

type ChannelTitleProps = {
  children?: React.ReactNode;
  dataHook?: string;
};
const ChannelTitleElement = forwardRef<HTMLHeadingElement, ChannelTitleProps>(
  ({ children, dataHook }, ref) => (
    <h2 className={styles.title} data-hook={dataHook} ref={ref}>
      {children}
    </h2>
  ),
);

const useShouldShowTooltip = () => {
  const channel = useChannel();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [shouldShowTooltip, setShouldShowTooltip] = useState(true);

  useLayoutEffect(() => {
    if (!titleRef.current) {
      return;
    }
    const { offsetWidth, offsetHeight, scrollWidth, scrollHeight } =
      titleRef.current;
    setShouldShowTooltip(
      offsetWidth < scrollWidth || offsetHeight < scrollHeight,
    );
  }, [channel.title]);

  return {
    titleRef,
    shouldShowTooltip,
  };
};

const ChannelTitle: FC = () => {
  const channel = useChannel();
  const { titleRef, shouldShowTooltip } = useShouldShowTooltip();

  const channelTitleElement = (
    <ChannelTitleElement dataHook="header-title" ref={titleRef}>
      {channel.title}
    </ChannelTitleElement>
  );

  return shouldShowTooltip ? (
    <Tooltip
      content={channel.title}
      appendTo="scrollParent"
      className={styles.titleTooltip}
    >
      {channelTitleElement}
    </Tooltip>
  ) : (
    channelTitleElement
  );
};

const ChannelTitlePlaceholder: FC = () => <ChannelTitleElement />;

export const Header: FC<{ renderShareButton: () => React.ReactNode }> = ({
  renderShareButton,
}) => {
  const channelAccessButton = useChannelAccessButton();

  const { showTitle, showChannelInfo, showChannelShare, showSignIn } =
    useHeaderSettings();

  return (
    <header className={styles.root}>
      {showTitle ? <ChannelTitle /> : <ChannelTitlePlaceholder />}
      <div className={styles.buttons}>
        {showChannelShare ? (
          <ButtonWrapper>{renderShareButton()}</ButtonWrapper>
        ) : null}
        {showChannelInfo ? (
          <ButtonWrapper>
            <ChannelInfoButton />
          </ButtonWrapper>
        ) : null}
        {channelAccessButton ? (
          <ButtonWrapper>
            <ChannelAccessButton />
          </ButtonWrapper>
        ) : null}
        {showSignIn ? (
          <ButtonWrapper>
            <HeaderAccountInfo />
          </ButtonWrapper>
        ) : null}
      </div>
    </header>
  );
};
