import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  Divider,
  IconButton,
  Popover,
  PopoverTriggerAction,
  Text,
  TextButtonPriority,
} from 'wix-ui-tpa';
import { classes, st } from './HeaderLoggedIn.st.css';
import { useWorker } from '../../../hooks/useWorker';
import UserIcon from 'wix-ui-icons-common/on-stage/User';
import { TextButton } from '../../../common/components/TextButton/TextButton';
import { useChannelPricing } from '../../../hooks';
import { useUserEmail } from '../../../hooks/useUserEmail';

const useSignOut = () => {
  const { user } = useWorker();
  const { signOut } = user;
  return () => signOut();
};

const UserIconButton: FC<{ dataHook: string; onClick?: () => void }> = ({
  dataHook,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <IconButton
      className={st(classes.accountButton)}
      aria-label={t('widget.accessibility.my-account')}
      icon={<UserIcon aria-hidden="true" />}
      data-hook={dataHook}
      onClick={onClick}
    />
  );
};

const AccountInfo: FC = () => {
  const { t } = useTranslation();
  const signOut = useSignOut();
  const email = useUserEmail();
  return (
    <section className={classes.accountInfo}>
      <Text
        role="heading"
        aria-label={t('widget.account.signed-as-email', { email })}
        tagName="div"
        className={classes.userEmail}
      >
        {email}
      </Text>
      <Divider />
      <div className={classes.logoutWrapper}>
        <TextButton
          onClick={signOut}
          priority={TextButtonPriority.secondary}
          data-hook="logout-button"
        >
          {t('widget.account.logout')}
        </TextButton>
      </div>
    </section>
  );
};

const AccountTooltipIcon: FC = () => (
  <Popover
    placement="auto"
    showArrow
    triggerAction={PopoverTriggerAction.hover}
    hideDelay={300}
    data-hook="account-popover"
  >
    <Popover.Element>
      <UserIconButton dataHook="account-tooltip-icon" />
    </Popover.Element>
    <Popover.Content>
      <AccountInfo />
    </Popover.Content>
  </Popover>
);

const useOpenAccountInfo = () => {
  const {
    modals: { openAccountInfo },
  } = useWorker();
  return () => openAccountInfo();
};

const AccountButton: FC = () => {
  const openAccountInfo = useOpenAccountInfo();

  return <UserIconButton onClick={openAccountInfo} dataHook="account-button" />;
};

export const HeaderLoggedIn: FC = () => {
  const { isSubscriptionChannel } = useChannelPricing();

  return isSubscriptionChannel ? <AccountButton /> : <AccountTooltipIcon />;
};
