import { useBi } from '@wix/yoshi-flow-editor';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { widgetLoadComplete, widgetOpened } from '@wix/bi-logger-video/v2';
import { LogObject } from '@wix/bi-logger-video/v2/types';

type BiReadinessContextType = {
  reportWidgetOpened: () => void;
  reportWidgetLoadComplete: () => void;
};

const BiReadinessContext = createContext<BiReadinessContextType | null>(null);

const useBiReportOnce = <T extends {}>(logObject: LogObject<T>) => {
  const alreadySentRef = useRef(false);
  const bi = useBi();

  return useCallback(() => {
    if (!alreadySentRef.current) {
      bi.report(logObject);
      alreadySentRef.current = true;
    }
  }, [bi, logObject]);
};

const useBiReadinessValue = (): BiReadinessContextType => {
  const biReportWidgetOpened = useBiReportOnce(
    useMemo(() => widgetOpened({}), []),
  );
  const biReportWidgetLoadComplete = useBiReportOnce(
    useMemo(() => widgetLoadComplete({}), []),
  );

  return useMemo<BiReadinessContextType>(
    () => ({
      reportWidgetOpened: () => {
        biReportWidgetOpened();
      },
      reportWidgetLoadComplete: () => {
        biReportWidgetLoadComplete();
      },
    }),
    [biReportWidgetOpened, biReportWidgetLoadComplete],
  );
};

export const useBiReadinessContext = (): BiReadinessContextType => {
  const biReadinessContext = useContext(BiReadinessContext);
  if (!biReadinessContext) {
    throw Error(
      'useBiReadinessContext() must be called inside <BiReadinessProvider>',
    );
  }
  return biReadinessContext;
};

export const BiReadinessProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const biReadinessValue = useBiReadinessValue();
  const { reportWidgetOpened } = biReadinessValue;
  useEffect(() => {
    reportWidgetOpened();
  }, [reportWidgetOpened]);
  return (
    <BiReadinessContext.Provider value={biReadinessValue}>
      {children}
    </BiReadinessContext.Provider>
  );
};
