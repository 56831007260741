import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2;

import { ResizeSensor } from 'css-element-queries';
import { VIDEO_EVENTS, UI_EVENTS, EngineState as STATES } from 'playable/dist/src/constants';
import playerAPI from 'playable/dist/src/core/player-api-decorator';
import { noop } from 'lodash';
import CardsContainer from './cards-container/cards-container';
import CardsManager from './cards-manager/cards-manager';
import CardsConfig from './cards-config/cards-config';
import { EVENTS } from './constants';
import { waitForDomUpdate } from './utils/dom-update-delay';
let CardsModule = (_dec = playerAPI(), _dec2 = playerAPI(), _dec3 = playerAPI(), _dec4 = playerAPI(), _dec5 = playerAPI(), _dec6 = playerAPI(), _dec7 = playerAPI(), _dec8 = playerAPI(), _dec9 = playerAPI(), _dec10 = playerAPI(), _dec11 = playerAPI(), _dec12 = playerAPI(), (_class = (_class2 = class CardsModule {
  constructor(_ref) {
    let eventEmitter = _ref.eventEmitter,
        rootContainer = _ref.rootContainer,
        engine = _ref.engine;
    this.eventEmitter = eventEmitter;
    this.rootContainer = rootContainer;
    this.engine = engine;
    this.cardsConfig = new CardsConfig();
    this.isInitialized = false;
    this.hide = noop;
    this.show = noop;
    this.handlePlayerSizeChange = this.handlePlayerSizeChange.bind(this);
  } // Lazy initialization of cards-related stuff


  initialize() {
    if (this.isInitialized) {
      return;
    }

    this.initContainer();
    this.initManager();
    this.initEventListeners();

    this.hide = () => this.cardsContainer.hide();

    this.show = () => this.cardsContainer.show();

    this.isInitialized = true;
    setTimeout(() => this.handlePlayerStateChange({
      nextState: this.engine.getCurrentState()
    }), 0);
  }

  initContainer() {
    this.cardsContainer = new CardsContainer({
      cardsConfig: this.cardsConfig
    });
    this.rootContainer.appendComponentElement(this.cardsContainer.node);
  }

  initManager() {
    this.cardsManager = new CardsManager({
      cardsContainer: this.cardsContainer,
      eventEmitter: this.eventEmitter,
      cardsConfig: this.cardsConfig,
      engine: this.engine
    });
  }

  initEventListeners() {
    this.eventEmitter.on(VIDEO_EVENTS.STATE_CHANGED, this.handlePlayerStateChange, this);
    this.eventEmitter.on(UI_EVENTS.MAIN_BLOCK_HIDE, this.cardsContainer.onControlsHidden, this.cardsContainer);
    this.eventEmitter.on(UI_EVENTS.MAIN_BLOCK_SHOW, this.cardsContainer.onControlsShown, this.cardsContainer); // wait for playerContainerNode inserted to DOM and get size for case if it's not added yet

    waitForDomUpdate().then(() => this.resizeSensor = new ResizeSensor(this.rootContainer.getElement(), this.handlePlayerSizeChange));
    this.eventEmitter.on(EVENTS.CARD_CLOSED, this.cardsManager.handleCardClose, this.cardsManager);
  }

  addCard(cardData) {
    this.initialize();
    return this.cardsManager.addCard(cardData);
  }

  addCards(cardsData) {
    this.initialize();
    return this.cardsManager.addCards(cardsData);
  }

  removeCard(id) {
    this.cardsManager.removeCard(id);
  }

  updateCards(cardsData) {
    return this.cardsManager.updateCards(cardsData);
  }

  clearCards() {
    if (!this.isInitialized) {
      return;
    }

    this.cardsManager.clearCards();
  }

  setAnchorPoint(anchorPoint) {
    if (this.cardsConfig.anchorPoint === anchorPoint) {
      return;
    }

    this.cardsConfig.anchorPoint = anchorPoint;
  }

  setOrientation(orientation) {
    if (this.cardsConfig.orientation === orientation) {
      return;
    }

    this.cardsConfig.orientation = orientation;
  }

  onCardClose(callback) {
    this.eventEmitter.on(EVENTS.CARD_CLOSED, callback);
  }

  onCardShown(callback) {
    this.eventEmitter.on(EVENTS.CARD_SHOWN, callback);
  }

  onCardHidden(callback) {
    this.eventEmitter.on(EVENTS.CARD_HIDDEN, callback);
  }

  setCardsClosable(isClosable) {
    const isPreviewMode = !isClosable;

    if (this.cardsConfig.isPreviewMode === isPreviewMode) {
      return;
    }

    this.cardsConfig.isPreviewMode = isPreviewMode;
  }

  setActiveCard(id) {
    if (this.isInitialized) {
      return this.cardsManager.showSelectedCard(id);
    }
  }

  handlePlayerSizeChange() {
    this.cardsManager.handlePlayerSizeChange();
  }

  handlePlayerStateChange(_ref2) {
    let nextState = _ref2.nextState;

    switch (nextState) {
      case STATES.PLAYING:
        this.cardsManager.handleVideoPlayStart();
        break;

      case STATES.PAUSED:
        this.cardsManager.handleVideoPlayPause();
        break;

      case STATES.SEEK_IN_PROGRESS:
        this.cardsManager.handleSeekPositionChange();
        break;

      default:
        break;
    }
  }

  unbindEvents() {
    this.eventEmitter.off(VIDEO_EVENTS.STATE_CHANGED, this.handlePlayerStateChange, this);
    this.eventEmitter.off(UI_EVENTS.MAIN_BLOCK_HIDE, this.cardsContainer.onControlsHidden, this.cardsContainer);
    this.eventEmitter.off(UI_EVENTS.MAIN_BLOCK_SHOW, this.cardsContainer.onControlsShown, this.cardsContainer);
    this.eventEmitter.off(EVENTS.CARD_CLOSED);
    this.eventEmitter.off(EVENTS.CARD_SHOWN);
    this.eventEmitter.off(EVENTS.CARD_HIDDEN);
  }

  destroy() {
    if (!this.isInitialized) {
      return;
    }

    this.unbindEvents();
    this.cardsContainer.destroy();
    this.cardsManager.destroy();
    this.cardsConfig.destroy();

    if (this.resizeSensor) {
      this.resizeSensor.detach(this.handlePlayerSizeChange);
    }

    delete this.cardsContainer;
    delete this.cardsManager;
    delete this.cardsConfig;
  }

}, _class2.dependencies = ['eventEmitter', 'rootContainer', 'engine'], _class2), (_applyDecoratedDescriptor(_class.prototype, "addCard", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addCards", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCard", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCards", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearCards", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "clearCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAnchorPoint", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setAnchorPoint"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOrientation", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setOrientation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCardClose", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onCardClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCardShown", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onCardShown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCardHidden", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onCardHidden"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCardsClosable", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setCardsClosable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setActiveCard", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setActiveCard"), _class.prototype)), _class));
export { CardsModule as default };