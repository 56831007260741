import React, { FC, useCallback } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { usePlayback, useSelectedVideo } from '../../../hooks';
import stylesParams from '../../../../../stylesParams';
import { VIDEO_END_OPTIONS } from '@wix/wix-vod-constants/common';
import { GqlVideoFragment } from '../../../apollo/generated/graphql';
import { useVideoAccessAction } from '../../../hooks/useVideoAccessAction';
import { AccessAction } from '../../../types/enums';

type Children = (handleEnd: () => void) => React.ReactElement;

const PlayNextVideo: FC<{
  nextVideo: GqlVideoFragment;
  children: Children;
}> = ({ nextVideo, children }) => {
  const { play } = usePlayback();
  const { selectVideo } = useSelectedVideo();
  const action = useVideoAccessAction(nextVideo);

  const handleEnd = useCallback(() => {
    selectVideo(nextVideo);

    if (action === AccessAction.None) {
      play();
    }
  }, [selectVideo, nextVideo, action, play]);

  return children(handleEnd);
};

const OpenActionCards: FC<{
  children: Children;
}> = ({ children }) => {
  // TODO: open action cards
  const handleEnd = useCallback(() => {}, []);

  return children(handleEnd);
};

export const VideoEndHandler: FC<{
  children: Children;
}> = ({ children }) => {
  const styles = useStyles();
  const videoEndOption: VIDEO_END_OPTIONS = styles.get(
    stylesParams.videoEndOption,
  );
  const { nextVideo } = useSelectedVideo();

  if (videoEndOption === VIDEO_END_OPTIONS.PLAY_NEXT_VIDEO && nextVideo) {
    return <PlayNextVideo nextVideo={nextVideo}>{children}</PlayNextVideo>;
  }

  if (videoEndOption === VIDEO_END_OPTIONS.OPEN_ACTION_CARDS) {
    return <OpenActionCards>{children}</OpenActionCards>;
  }

  const defaultEndHandler = () => {};
  return children(defaultEndHandler);
};
