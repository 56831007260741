import { HttpClient } from '@wix/yoshi-flow-editor';
import { FetchModuleProps } from './fetchModule';

export class FetchAdapter {
  private readonly httpClient: HttpClient;
  private requestCounter = 0;
  private resolvers: Record<string, any> = {};

  constructor(
    instance: string,
    private readonly doPost: FetchModuleProps['doPost'],
    private readonly removeResponse: FetchModuleProps['removeResponse'],
  ) {
    this.httpClient = new HttpClient({
      getAppToken: () => instance,
    });
  }

  async post(uri: string, body: string): Promise<Response> {
    return new Promise<Response>((resolve) => {
      const requestId = this.requestCounter.toString();

      this.resolvers[requestId] = resolve;
      this.doPost(requestId, uri, body);
      this.requestCounter++;
    });
  }

  setResponses(responses: FetchModuleProps['responses']) {
    for (const [requestId, data] of Object.entries(responses)) {
      const resolve = this.resolvers[requestId];

      if (resolve) {
        resolve(new Response(JSON.stringify(data)));
        this.removeResponse(requestId);
      }
    }
  }
}
