import { useBi } from '@wix/yoshi-flow-editor';
import { widgetVidShareClicked } from '@wix/bi-logger-video/v2';
import {
  useChannel,
  useChannelId,
  useNotForPreview,
  useSelectedVideo,
} from '../../../hooks';
import { useSiteInfo } from '../../../common/providers/SiteInfoProvider';
import { ShareMode } from './ShareMode';
import { getResizedImageUrl } from '../../../utils/getResizedImageUrl';

const POPUP_PARAMS = {
  toolbar: false,
  location: false,
  directories: false,
  status: false,
  menubar: false,
  scrollbars: true,
  resizable: true,
  copyhistory: false,
};

const getWindowFeatures = (
  params: Record<string, number | boolean | string>,
): string =>
  Object.entries(params)
    .map(([key, value]) => {
      let formattedValue: string;
      if (value === true) {
        formattedValue = 'yes';
      } else if (value === false) {
        formattedValue = 'no';
      } else {
        formattedValue = String(value);
      }
      return `${key}=${formattedValue}`;
    })
    .join(',');

const getSizeParams = () => {
  const width = 980;
  const height = 600;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  return { width, height, left, top };
};

export const openSharePopup = (url: string) => {
  const params = { ...POPUP_PARAMS, ...getSizeParams() };
  const features = getWindowFeatures(params);

  window.open(url, '_blank', features);
};

const SHARE_MAPPING_PATH = '_api/media-share-server-for-video';

// this should never be explicitly got
// it is used because was added here: https://github.com/wix-private/wix-vod/pull/5342
// to pass to media-share-server so that it could send it to BI.
// instead the server should use BI logger that passes this parameter
const getBsi = () => {
  return (
    (window as { commonConfig?: { bsi?: string } }).commonConfig?.bsi ??
    getCookie('bSession')
  );
};

const getCookie = (cookieName: string) => {
  const cookies = Object.fromEntries(
    document.cookie.split(';').map((pair) => pair.trim().split('=')),
  );
  return cookies[cookieName];
};

export const useMediaShareUrl = (mode: ShareMode) => {
  const siteInfo = useSiteInfo();
  const channelId = useChannelId();
  const { selectedVideo } = useSelectedVideo();
  const { instanceId, compId, biToken, siteUrl, currentPageId } = siteInfo;
  const url = new URL(siteUrl);
  url.pathname = `${SHARE_MAPPING_PATH}/${currentPageId}`;
  url.searchParams.set('instance-id', instanceId);
  url.searchParams.set('component-id', compId);
  url.searchParams.set('bi-token', biToken);
  url.searchParams.set('bsi', getBsi());
  url.searchParams.set('channel-id', channelId);
  if (mode === 'video') {
    url.searchParams.set('video-id', selectedVideo.id);
  }
  return url.href;
};

export const useDirectShareUrl = (mode: ShareMode) => {
  const { siteUrl: siteUrlStr, compId } = useSiteInfo();
  const { selectedVideo } = useSelectedVideo();
  const siteUrl = new URL(siteUrlStr);
  siteUrl.searchParams.set('wix-vod-comp-id', compId);
  if (mode === 'video') {
    siteUrl.searchParams.set('wix-vod-video-id', selectedVideo.id);
  }
  return siteUrl.href;
};

type NetworkName = 'facebook' | 'twitter' | 'pinterest' | 'tumblr' | 'link';

type ShareBi = () => void;

export const useShareBi = (
  networkName: NetworkName,
  mode: ShareMode,
): ShareBi => {
  const bi = useBi();
  const channelId = useChannelId();
  const { selectedVideo } = useSelectedVideo();

  return () => {
    bi.report(
      widgetVidShareClicked({
        button: networkName,
        shareType: mode,
        channelID: channelId,
        videoID: mode === 'video' ? selectedVideo.id : undefined,
      }),
    );
  };
};

type ShareContent = {
  title: string;
  description: string;
  coverUrl: string;
};

const SHARE_IMAGE_WIDTH = 480;
const SHARE_IMAGE_HEIGHT = 360;

const getResizedShareImage = (url: string | undefined) =>
  url
    ? getResizedImageUrl(url, {
        width: SHARE_IMAGE_WIDTH,
        height: SHARE_IMAGE_HEIGHT,
      })
    : '';

const useChannelShareContent = (): ShareContent => {
  const channel = useChannel();
  return {
    title: channel.title,
    description: channel.description ?? '',
    coverUrl: getResizedShareImage(channel.cover.selected),
  };
};
const useVideoShareContent = (): ShareContent => {
  const { selectedVideo } = useSelectedVideo();
  return {
    title: selectedVideo.title,
    description: selectedVideo.description ?? '',
    coverUrl: getResizedShareImage(selectedVideo.cover.selected),
  };
};

type ShareParams = {
  mediaShareUrl: string; // a link to media-share-server which will redirect to directShareUrl
  directShareUrl: string; // a direct link with wix-vod-* query params
  shareContent: ShareContent;
};

export const useShare = (
  networkName: NetworkName,
  mode: ShareMode,
  getShareUrl: (shareParams: ShareParams) => string,
) => {
  const shareBi = useShareBi(networkName, mode);
  const notForPreview = useNotForPreview();
  const mediaShareUrl = useMediaShareUrl(mode);
  const directShareUrl = useDirectShareUrl(mode);
  const videoShareContent = useVideoShareContent();
  const channelShareContent = useChannelShareContent();
  const shareContent =
    mode === 'video' ? videoShareContent : channelShareContent;

  return notForPreview(() => {
    const url = getShareUrl({ mediaShareUrl, directShareUrl, shareContent });
    openSharePopup(url);
    shareBi();
  });
};
