import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelectedVideo } from '../../../hooks';
import { PlayerOverlayVideoInfoPoster } from '../PlayerOverlayVideoInfoPoster/PlayerOverlayVideoInfoPoster';
import { VideoInfoText, VideoInfoTextProps } from './VideoInfoText';
import styles from './PlayerOverlayVideoInfo.scss';
import { useDuration } from '../../../hooks/useDuration';
import { usePlayableFullScreenManager } from '../../../common/components/PlayableFullScreenManager/PlayableFullScreenManager';

const VideoTitle: FC<{ title: string }> = ({ title }) => {
  const { isFullScreen } = usePlayableFullScreenManager();

  return (
    <h3 className={styles.title} title={title}>
      <VideoInfoText size={isFullScreen ? 'large' : 'normal'}>
        {title}
      </VideoInfoText>
    </h3>
  );
};

const VideoInfoItem: FC<
  { infoItem?: string } & Omit<VideoInfoTextProps, 'children'>
> = ({ infoItem, ...props }) =>
  infoItem ? (
    <span className={styles.infoItem}>
      <VideoInfoText {...props}>{infoItem}</VideoInfoText>
    </span>
  ) : null;

const Publisher: FC<{ publisher?: string }> = ({ publisher }) => (
  <VideoInfoItem infoItem={publisher} />
);
const Genre: FC<{ genre?: string }> = ({ genre }) => (
  <VideoInfoItem infoItem={genre} />
);
const VideoType: FC<{ videoType?: string }> = ({ videoType }) => (
  <VideoInfoItem infoItem={videoType} />
);
const Duration: FC<{ duration?: number }> = ({ duration }) => {
  const durationStr = useDuration(duration);
  const { t } = useTranslation();
  if (typeof duration !== 'number') {
    return null;
  }
  return (
    <VideoInfoItem
      infoItem={durationStr}
      aria-label={t('widget.accessibility.duration', {
        durationStr,
      })}
    />
  );
};

const Info: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={styles.info}>{children}</div>
);

const Description: FC<{ description?: string }> = ({ description }) =>
  description ? (
    <div className={styles.description}>
      <VideoInfoText tagName="p" textTheme="description">
        {description}
      </VideoInfoText>
    </div>
  ) : null;

const Roles: FC<{
  title: string;
  roles?: { role?: string; name?: string }[];
}> = ({ title, roles }) => {
  if (!roles) {
    return null;
  }
  const filteredRoles = roles.filter(({ role }) => Boolean(role)) as {
    role: string;
    name?: string;
  }[];
  if (!filteredRoles.length) {
    return null;
  }
  return (
    <section className={styles.rolesWrapper}>
      <VideoInfoText tagName="h4" textTheme="rolesTitle" size="small">
        {title}
      </VideoInfoText>
      <ul className={styles.rolesList}>
        {filteredRoles.map(({ role, name }) => (
          <li className={styles.roleItem} key={role}>
            <VideoInfoText textTheme="role">{role}</VideoInfoText>
            <VideoInfoText textTheme="roleName">{name}</VideoInfoText>
          </li>
        ))}
      </ul>
    </section>
  );
};

export const PlayerOverlayVideoInfo: FC = () => {
  const { selectedVideo } = useSelectedVideo();
  const { isFullScreen } = usePlayableFullScreenManager();
  const { t } = useTranslation();
  return (
    <section
      className={classNames(styles.wrapper, {
        [styles.fullScreen]: isFullScreen,
      })}
    >
      <div className={styles.videoPosterWrapper}>
        <PlayerOverlayVideoInfoPoster
          posterUrl={selectedVideo.poster.selected}
        />
      </div>
      <div className={styles.mainInfo}>
        <VideoTitle title={selectedVideo.title} />
        <Info>
          <Publisher publisher={selectedVideo.publisher} />
          <Genre genre={selectedVideo.genre} />
          <VideoType videoType={selectedVideo.type} />
          <Duration duration={selectedVideo.content?.duration} />
        </Info>
        <Description description={selectedVideo.description} />
        <Roles
          title={t('player.info-overlay.cast')}
          roles={selectedVideo.cast}
        />
        <Roles
          title={t('player.info-overlay.crew')}
          roles={selectedVideo.crew}
        />
      </div>
    </section>
  );
};
