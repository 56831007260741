import React, { FC } from 'react';
import styles from './ActionsPanel.scss';
import { useCurrentPlayerOverlay } from '../PlayerOverlayProvider/PlayerOverlayProvider';
import classNames from 'classnames';

const useIsOverlayShown = (): boolean => {
  const { currentOverlay } = useCurrentPlayerOverlay();
  return Boolean(currentOverlay);
};

export const ActionsPanel: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const isOverlayShown = useIsOverlayShown();

  return (
    <section
      className={classNames(styles.mainContentWrapper, {
        [styles.hidden]: isOverlayShown,
      })}
    >
      <div className={styles.actions}>{children}</div>
    </section>
  );
};
