import React, { FC } from 'react';
import { PlaybackComponentProps } from '../Player.types';
import ReactPlayerLazy from 'react-player/lazy';

export const ReactPlayer: FC<PlaybackComponentProps> = ({
  src,
  playing,
  muted,
  onEnd,
  onPause,
  onPlay,
}) => {
  return (
    <ReactPlayerLazy
      url={src}
      controls
      playing={playing}
      onEnded={onEnd}
      onPause={onPause}
      onPlay={onPlay}
      muted={muted}
      width="100%"
      height="100%"
    />
  );
};
