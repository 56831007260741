import { createContext, useCallback, useContext, useMemo } from 'react';
import { GqlVideosFilter } from '../apollo/generated/graphql';
import { isEqual } from 'lodash';

export const defaultFilter: GqlVideosFilter = {
  category: '',
  query: '',
  tag: '',
};

type VideosFilterContextType = {
  filter: GqlVideosFilter;
  setFilter(filter: GqlVideosFilter): void;
};

export const VideosFilterContext = createContext<VideosFilterContextType>({
  filter: defaultFilter,
  setFilter: () => {},
});

export function useVideosFilter() {
  const context = useContext(VideosFilterContext);
  const { filter, setFilter } = context;

  const isEmptyFilter = useMemo(() => isEqual(filter, defaultFilter), [filter]);

  const setQuery = useCallback(
    (query: string) => {
      setFilter({
        ...defaultFilter,
        query,
      });
    },
    [setFilter],
  );

  const setTag = useCallback(
    (tag: string) => {
      setFilter({
        ...defaultFilter,
        tag,
      });
    },
    [setFilter],
  );

  const setCategory = useCallback(
    (category: string) => {
      setFilter({
        ...defaultFilter,
        category,
      });
    },
    [setFilter],
  );

  const clearFilter = useCallback(() => {
    setFilter(defaultFilter);
  }, [setFilter]);

  return {
    filter,
    setQuery,
    setTag,
    setCategory,
    clearFilter,
    isEmptyFilter,
  };
}
