import React, { FC } from 'react';
import ReactFocusLock from 'react-focus-lock';

type GetProps<T extends React.Component> = T extends React.Component<infer P>
  ? P
  : never;

type Props = {
  children: React.ReactNode;
} & GetProps<ReactFocusLock>;

export const FocusLock: FC<Props> = ({ children, className }) => {
  return (
    <ReactFocusLock className={className} returnFocus autoFocus>
      {children}
    </ReactFocusLock>
  );
};
