import { InMemoryCache } from '@apollo/client';
import { StrictTypedTypePolicies } from './generated/apollo-helpers';

const typePolicies: StrictTypedTypePolicies = {
  Channel: {
    merge: (existing, incoming, { args, toReference, mergeObjects }) => {
      // we use 2 queries on the same type Channel.
      // To merge both results into same cache item we should do this:
      // find existing reference and merge incoming videos
      const ref = toReference({
        __typename: 'Channel',
        id: args?.filter.id,
      });
      return mergeObjects(ref, incoming);
    },
    fields: {
      videos: {
        keyArgs: ['filter'],
        merge(existing, incoming) {
          const entities = existing?.entities || [];

          return {
            cursor: incoming.cursor,
            entities: [...entities, ...incoming.entities],
          };
        },
      },
    },
  },
};

export const createApolloCache = () => {
  return new InMemoryCache({
    typePolicies,
  });
};
