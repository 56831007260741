import React, { FC, useCallback } from 'react';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { Button } from '../../../../common/components/Button/Button';
import { useChannelId, usePlayback, useSelectedVideo } from '../../../../hooks';
import { widgetVidClicked } from '@wix/bi-logger-video/v2';
import {
  GqlVideoFragment,
  GqlVideoSource,
} from '../../../../apollo/generated/graphql';
import { useVideoAccessAction } from '../../../../hooks/useVideoAccessAction';
import { AccessAction } from '../../../../types/enums';

const videoTypeMap: {
  [key in GqlVideoSource]: string;
} = {
  [GqlVideoSource.Facebook]: 'facebook',
  [GqlVideoSource.Youtube]: 'youtube',
  [GqlVideoSource.Vimeo]: 'vimeo',
  [GqlVideoSource.Live]: 'live',
  [GqlVideoSource.Computer]: 'computer',
  [GqlVideoSource.Dropbox]: 'computer',
  [GqlVideoSource.GoogleDrive]: 'computer',
};

const useLogBI = () => {
  const bi = useBi();
  const channelID = useChannelId();
  const { selectedVideo } = useSelectedVideo();

  return useCallback(() => {
    bi.report(
      widgetVidClicked({
        channelID,
        videoID: selectedVideo.id,
        videoType: videoTypeMap[selectedVideo.source],
        buttonName: 'next_video',
      }),
    );
  }, [bi, channelID, selectedVideo]);
};

const PlayNextVideoButton: FC<{
  nextVideo: GqlVideoFragment;
}> = ({ nextVideo }) => {
  const { t } = useTranslation();
  const logBi = useLogBI();
  const { selectVideo } = useSelectedVideo();
  const { play } = usePlayback();
  const accessAction = useVideoAccessAction(nextVideo);

  const handleClick = useCallback(() => {
    logBi();

    if (nextVideo) {
      selectVideo(nextVideo);
    }

    if (accessAction === AccessAction.None) {
      play();
    }
  }, [nextVideo, selectVideo, play, logBi, accessAction]);

  return (
    <Button onClick={handleClick}>
      {t('widget.overlay-buttons.next-video')}
    </Button>
  );
};

export const PlayNextButton: FC = () => {
  const { nextVideo } = useSelectedVideo();

  return nextVideo ? <PlayNextVideoButton nextVideo={nextVideo} /> : null;
};
