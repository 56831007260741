import React, { FC } from 'react';
import { CustomDialog } from 'wix-ui-tpa';
import { usePreviewPrompt } from '../../providers/PreviewProvider';

export const PreviewPrompt: FC = () => {
  const { isOpen, close } = usePreviewPrompt();
  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={close}
      data-hook="preview-prompt-dialog"
    >
      Preview Prompt1
      <button onClick={close}>close</button>
    </CustomDialog>
  );
};
