import classNames from 'classnames';
import React, { FC } from 'react';
import { Spinner as TPASpinner, SpinnerProps } from 'wix-ui-tpa';
import { st, classes } from './Spinner.st.css';

export const Spinner: FC<SpinnerProps> = ({ className, ...rest }) => {
  return (
    <TPASpinner
      diameter={50}
      isCentered={true}
      className={classNames(st(classes.root), className)}
      {...rest}
    />
  );
};
