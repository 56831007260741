import React, { FC } from 'react';
import { DarkShareSocialButton } from './DarkShareSocialButton';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ShareMode } from './ShareMode';
import { useShare } from './useShare';
import TwitterIcon from 'wix-ui-icons-common/on-stage/Twitter';
import { useSiteInfo } from '../../../common/providers/SiteInfoProvider';
import { useSelectedVideo } from '../../../hooks';
import PinterestIcon from 'wix-ui-icons-common/on-stage/Pinterest';
import TumblrIcon from 'wix-ui-icons-common/on-stage/Tumblr';

const BASE_TUMBLR_SHARE_URL = 'https://www.tumblr.com/widgets/share/tool';

const useShareToTumblr = (mode: ShareMode) => {
  const { t } = useTranslation();

  return useShare(
    'tumblr',
    mode,
    ({ directShareUrl: shareUrl, shareContent }) => {
      const descriptionText = t('social-share.network.tumblr', {
        title: shareContent.title,
        siteUrl: shareUrl,
      });
      const tumblrUrl = new URL(BASE_TUMBLR_SHARE_URL);
      tumblrUrl.searchParams.set('posttype', 'photo');
      tumblrUrl.searchParams.set('caption', descriptionText);
      tumblrUrl.searchParams.set('content', shareContent.coverUrl);
      tumblrUrl.searchParams.set('url', shareUrl);
      return tumblrUrl.href;
    },
  );
};

export const DarkShareTumblr: FC<{
  mode: ShareMode;
}> = ({ mode }) => {
  const { t } = useTranslation();
  const shareToTumblr = useShareToTumblr(mode);

  return (
    <DarkShareSocialButton
      Icon={TumblrIcon}
      onClick={shareToTumblr}
      tooltipText={t('social-button.tumblr')}
      ariaLabel={t('widget.accessibility.share-on', {
        network: 'Tumblr',
      })}
      dataHook="dark-share-tumblr"
    />
  );
};
