import React, { FC } from 'react';
import { DarkShareSocialButton } from './DarkShareSocialButton';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ShareMode } from './ShareMode';
import { useShare } from './useShare';
import PinterestIcon from 'wix-ui-icons-common/on-stage/Pinterest';

const BASE_PINTEREST_SHARE_URL = 'https://www.pinterest.com/pin/create/button/';

const useShareToPinterest = (mode: ShareMode) => {
  const { t } = useTranslation();

  return useShare(
    'pinterest',
    mode,
    ({ directShareUrl: shareUrl, shareContent }) => {
      const descriptionText = t('social-share.network.pinterest', {
        title: shareContent.title,
        description: shareContent.description,
      });
      const pinterestUrl = new URL(BASE_PINTEREST_SHARE_URL);
      pinterestUrl.searchParams.set('description', descriptionText);
      pinterestUrl.searchParams.set('media', shareContent.coverUrl);
      pinterestUrl.searchParams.set('url', shareUrl);
      return pinterestUrl.href;
    },
  );
};

export const DarkSharePinterest: FC<{
  mode: ShareMode;
}> = ({ mode }) => {
  const { t } = useTranslation();
  const shareToPinterest = useShareToPinterest(mode);

  return (
    <DarkShareSocialButton
      Icon={PinterestIcon}
      onClick={shareToPinterest}
      tooltipText={t('social-button.pinterest')}
      ariaLabel={t('widget.accessibility.share-on', {
        network: 'Pinterest',
      })}
      dataHook="dark-share-pinterest"
    />
  );
};
