import React, { FC } from 'react';
import { IconButton } from 'wix-ui-tpa';
import CloseIcon from 'wix-ui-icons-common/on-stage/Close';
import { classes } from './DarkOverlayCloseButton.st.css';

export const DarkOverlayCloseButton: FC<{
  ariaLabel: string;
  closeOverlay: () => void;
}> = ({ closeOverlay, ariaLabel }) => {
  return (
    <IconButton
      className={classes.closeButton}
      icon={<CloseIcon />}
      onClick={closeOverlay}
      aria-label={ariaLabel}
      data-hook="dark-overlay-close-button"
    />
  );
};
