import React, { FC } from 'react';
import { DarkShareSocialButton } from './DarkShareSocialButton';
import FacebookIcon from 'wix-ui-icons-common/on-stage/Facebook';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ShareMode } from './ShareMode';
import { useShare } from './useShare';

const BASE_FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';

const useShareToFacebook = (mode: ShareMode) => {
  return useShare('facebook', mode, ({ mediaShareUrl: shareUrl }) => {
    const facebookUrl = new URL(BASE_FACEBOOK_SHARE_URL);
    facebookUrl.searchParams.set('u', shareUrl);
    facebookUrl.searchParams.set('ref', 'site');
    facebookUrl.searchParams.set('display', 'popup');
    return facebookUrl.href;
  });
};

export const DarkShareFacebook: FC<{
  mode: ShareMode;
}> = ({ mode }) => {
  const { t } = useTranslation();
  const shareToFacebook = useShareToFacebook(mode);

  return (
    <DarkShareSocialButton
      Icon={FacebookIcon}
      onClick={shareToFacebook}
      tooltipText={t('social-button.facebook')}
      ariaLabel={t('widget.accessibility.share-on', {
        network: 'Facebook',
      })}
      dataHook="dark-share-facebook"
    />
  );
};
