import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../stylesParams';
import { DesktopLayout } from '../types/enums';

export function useDesktopLayout() {
  const styles = useStyles();
  const channelLayout = styles.get(stylesParams.channelLayout);

  return {
    isClassic: channelLayout === DesktopLayout.Classic,
  };
}
