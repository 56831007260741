import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { chunk } from 'lodash';
import classNames from 'classnames';
import { useStyles } from '@wix/tpa-settings/react';
import { Thumbnail } from './Thumbnail/Thumbnail';
import stylesParams from '../../../../../../stylesParams';
import s from './Videos.scss';
import ChevronRightSmall from 'wix-ui-icons-common/on-stage/ChevronRightSmall';
import ChevronLeftSmall from 'wix-ui-icons-common/on-stage/ChevronLeftSmall';
import { TextButtonPriority } from 'wix-ui-tpa';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { useChannelVideos } from '../../../../hooks/useChannelVideos';
import { useVideosFilter } from '../../../../hooks/useVideosFilter';
import { VideoAspectRatio } from '../../../../common/components/VideoAspectRatio/VideoAspectRatio';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

export const Videos: FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const rootRef = useRef<HTMLDivElement>(null);
  const selectedPageRef = useRef<HTMLDivElement>(null);
  const numberOfRows = styles.get(stylesParams.numberOfRows);
  const videosInRow = styles.get(stylesParams.videosInRow);
  const videosPerPage = useMemo(
    () => videosInRow * numberOfRows,
    [videosInRow, numberOfRows],
  );
  const { filter } = useVideosFilter();
  const { videos, loadMoreVideos, videosSearching } = useChannelVideos();
  const pages = useMemo(
    () => chunk(videos, videosPerPage),
    [videos, videosPerPage],
  );
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const { isRTL } = useEnvironment();

  const scrollToPage = useCallback(
    async (index: number) => {
      const root = rootRef.current;

      if (root) {
        const page = rootRef.current?.children[index] as HTMLDivElement;

        if (page) {
          root.scrollLeft = page.offsetLeft;
        }

        if (index >= pages.length - 2) {
          setTimeout(() => {
            loadMoreVideos();
          }, 500);
        }
      }

      setSelectedPageIndex(index);
    },
    [loadMoreVideos, pages],
  );

  const handleNext = useCallback(
    () => scrollToPage(selectedPageIndex + 1),
    [scrollToPage, selectedPageIndex],
  );

  const handlePrev = useCallback(
    () => scrollToPage(selectedPageIndex - 1),
    [scrollToPage, selectedPageIndex],
  );

  const pageClassNames = useMemo(
    () =>
      classNames(
        s.page,
        s[`videosInRow_${videosInRow}`],
        s[`numberOfRows_${numberOfRows}`],
      ),
    [numberOfRows, videosInRow],
  );

  useEffect(() => {
    if (filter) {
      setSelectedPageIndex(0);
    }
  }, [filter]);

  // push height
  const emptyPage = (
    <div className={pageClassNames}>
      {new Array(videosPerPage).fill(undefined).map((_, i) => (
        <VideoAspectRatio key={i} />
      ))}
      {videosSearching && <Spinner />}
    </div>
  );

  return (
    <div>
      <div
        className={classNames(s.pages, {
          [s.scrollable]: false,
        })}
        data-hook="thumbnails-list"
        ref={rootRef}
      >
        {pages.length
          ? pages.map((pageVideos, i) => (
              <div
                key={pageVideos[pageVideos.length - 1].id}
                className={pageClassNames}
                ref={selectedPageIndex === i ? selectedPageRef : undefined}
              >
                {pageVideos.map((video) => (
                  <Thumbnail video={video} key={video.id} />
                ))}
              </div>
            ))
          : emptyPage}
      </div>
      <div className={s.buttons}>
        <TextButton
          onClick={handlePrev}
          priority={TextButtonPriority.secondary}
          prefixIcon={isRTL ? <ChevronRightSmall /> : <ChevronLeftSmall />}
          className={classNames(s.arrow, {
            [s.visible]: selectedPageIndex > 0,
          })}
        >
          {t('shared.navigation.previous-page')}
        </TextButton>
        <TextButton
          onClick={handleNext}
          priority={TextButtonPriority.secondary}
          suffixIcon={isRTL ? <ChevronLeftSmall /> : <ChevronRightSmall />}
          className={classNames(s.arrow, {
            [s.visible]: selectedPageIndex < pages.length - 1,
          })}
          data-hook="next-thumbnails-button"
        >
          {t('shared.navigation.next-page')}
        </TextButton>
      </div>
    </div>
  );
};
