import React, { FC, Reducer, useEffect, useReducer, useRef } from 'react';
import classNames from 'classnames';
import styles from './Animate.scss';

type Props = {
  className?: string;
  type?: 'fade-in' | 'fade-in-out';
  open?: boolean;
  shownClassName?: string;
  dataHook?: string;
  children: () => React.ReactNode;
  onAnimationEnd?: (active: boolean) => void;
};

type ActiveState = {
  active: boolean;
  hiding: boolean;
  showing: boolean;
};

type ActiveStateAction = 'show' | 'hide' | 'end';

const useActiveState = (open: boolean) => {
  const initRef = useRef(false);
  const [{ active, hiding, showing }, dispatch] = useReducer<
    Reducer<ActiveState, ActiveStateAction>
  >(
    (state, action) => {
      switch (action) {
        case 'show': {
          return {
            active: true,
            showing: true,
            hiding: false,
          };
        }
        case 'hide': {
          return {
            active: false,
            hiding: true,
            showing: false,
          };
        }
        case 'end': {
          return {
            active: state.active,
            hiding: false,
            showing: false,
          };
        }
      }
    },
    {
      active: open,
      hiding: false,
      showing: false,
    },
  );
  const onEnd = () => {
    dispatch('end');
  };
  useEffect(() => {
    // we don't want to run it the first time. If it's rendered with open={true} no dispatching nothing
    if (!initRef.current) {
      initRef.current = true;
      return () => {};
    }
    const timeoutId = window.setTimeout(() => {
      dispatch(open ? 'show' : 'hide');
    }, 0);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [open]);
  return { active, hiding, showing, onEnd };
};

export const Animate: FC<Props> = ({
  type = 'fade-in',
  children,
  open = true,
  className,
  shownClassName = '',

  dataHook,
  onAnimationEnd = () => {},
}) => {
  // we need to first render content and then fade it in
  const { active, hiding, showing, onEnd } = useActiveState(open);
  const shown = active || hiding || showing;

  const wrapperClassNames = classNames(
    className,
    { [shownClassName]: shown },
    styles.fadeCommon,
    type === 'fade-in' ? styles.fadeIn : styles.fadeInOut,
    { [styles.active]: active },
    { [styles.showing]: showing },
    { [styles.hiding]: hiding },
  );

  const onTransitionEnd = () => {
    onEnd();
    onAnimationEnd(active);
  };
  return (
    <div
      {...(dataHook && shown && { 'data-hook': dataHook })}
      className={wrapperClassNames}
      aria-hidden={!shown}
      onTransitionEnd={onTransitionEnd}
    >
      {shown ? children() : null}
    </div>
  );
};
