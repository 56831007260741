import React, { FC, useEffect } from 'react';
import { useDesktopLayout } from '../hooks/useDesktopLayout';
import { Classic } from './components/Classic/Classic';
import { useBiReadinessContext } from '../common/providers/BiReadinessProvider';

const useBiWidgetLoadComplete = () => {
  const { reportWidgetLoadComplete } = useBiReadinessContext();

  useEffect(() => {
    reportWidgetLoadComplete();
  }, [reportWidgetLoadComplete]);
};

export const DesktopWidget: FC = () => {
  const { isClassic } = useDesktopLayout();
  useBiWidgetLoadComplete();

  // TODO: loadable: https://bo.wix.com/wix-docs/fe-guild/viewer-platform/articles/loadable-components-in-ooi-apps
  if (isClassic) {
    return <Classic />;
  }

  return null;
};
