import React, { FC } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import {
  usePlayback,
  useSelectedVideo,
  useVideoAccessButton,
  useVideoSource,
} from '../../../../hooks';
import { useVideoPlayButton } from '../../../../hooks/useVideoPlayButton';
import { LiveLabel } from '../../../../common/components/LiveLabel/LiveLabel';
import stylesParams from '../../../../../../stylesParams';
import { Overlay, OverlayHeader, OverlayHeaderTitle } from '../Overlay/Overlay';
import { PlayNextButton } from '../PlayNextButton/PlayNextButton';
import s from './VideoOverlay.scss';

const useViewSettings = () => {
  const styles = useStyles();
  const showPublisher = styles.get(stylesParams.showPublisherForMainItem);
  const showTitle = styles.get(stylesParams.showTitleForMainItem);

  return { showPublisher, showTitle };
};

const usePlayButton = () => {
  const { play } = usePlayback();
  const { selectedVideo } = useSelectedVideo();
  const { playText, canPlay } = useVideoPlayButton(selectedVideo);

  return canPlay ? { text: playText, handler: play } : undefined;
};

const LiveLabelContainer: FC = () => {
  const { selectedVideo } = useSelectedVideo();
  const { isStreamingLive } = useVideoSource(selectedVideo);
  return (
    <div className={s.liveLabelContainer}>
      {isStreamingLive ? <LiveLabel /> : null}
    </div>
  );
};

const VideoOverlayPublisher: FC<{ children: string }> = ({ children }) => (
  <h4 className={s.publisher} data-hook="classic-overlay-publisher">
    {children}
  </h4>
);

const VideoOverlayButtons: FC = () => {
  const showPlayNextVideo = useShowPlayNextVideo();

  return showPlayNextVideo ? <PlayNextButton /> : null;
};

const VideoOverlayContent: FC = () => {
  const { selectedVideo } = useSelectedVideo();
  const { publisher } = selectedVideo;
  const { showPublisher, showTitle } = useViewSettings();
  const playButton = usePlayButton();

  const shouldShowPublisher = showPublisher && publisher;
  const shouldShowHeader = shouldShowPublisher || showTitle;

  return (
    <>
      <LiveLabelContainer />
      {shouldShowHeader ? (
        <OverlayHeader playButton={playButton}>
          {showTitle ? (
            <div className={s.titleWrapper}>
              <OverlayHeaderTitle>{selectedVideo.title}</OverlayHeaderTitle>
            </div>
          ) : null}
          {shouldShowPublisher ? (
            <div className={s.publisherWrapper}>
              <VideoOverlayPublisher>{publisher}</VideoOverlayPublisher>
            </div>
          ) : null}
        </OverlayHeader>
      ) : null}
    </>
  );
};

const useShowPlayNextVideo = () => {
  const { ended } = usePlayback();
  const { nextVideo } = useSelectedVideo();

  return nextVideo !== null && ended;
};

export const VideoOverlay: FC = () => {
  const { selectedVideo } = useSelectedVideo();
  const { playing } = usePlayback();
  const videoAccessButton = useVideoAccessButton(selectedVideo);
  const playButton = usePlayButton();

  return playing ? null : (
    <Overlay
      ariaLabel={selectedVideo.title}
      playButton={playButton}
      paidButton={videoAccessButton}
      coverUrl={selectedVideo.cover.selected}
      content={<VideoOverlayContent />}
      buttons={<VideoOverlayButtons />}
    />
  );
};
