import React, { FC, useEffect } from 'react';
import { useBi } from '@wix/yoshi-flow-editor';
import { widgetLoadComplete } from '@wix/bi-logger-video/v2';

export const MobileWidget: FC = () => {
  const bi = useBi();

  useEffect(() => {
    bi.report(widgetLoadComplete({}));
  }, [bi]);

  return <h1>Mobile</h1>;
};
