import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';
import SearchIcon from 'wix-ui-icons-common/Search';
import { Text } from 'wix-ui-tpa';
import { st, classes } from './NoResult.st.css';

export const NoResult: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.root)}>
      <SearchIcon width={40} height={40} />
      <Text className={st(classes.text)}>
        {t('widget.home-view.no-search-results')}
      </Text>
    </div>
  );
};
