import { getResizedImageUrl as getResizedImageUrlShared } from '@wix/wix-vod-shared/common';

type Size = { width: number; height: number };

// eventually we shouldn't use anything from wix-vod-shared,
// so creating this thin facade with only things we need
export const getResizedImageUrl = (url: string, { width, height }: Size) =>
  getResizedImageUrlShared({
    url,
    width,
    height,
  });
