import { ApolloClient, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { createApolloCache } from './cache';
import { FetchAdapter } from '../worker/fetch/fetchAdapter';

export function createApolloClient(
  gqlHost: string,
  instance: string,
  fetchAdapter: FetchAdapter,
  initialCacheData?: NormalizedCacheObject,
) {
  const cache = createApolloCache();
  if (initialCacheData) {
    cache.restore(initialCacheData);
  }

  // const customFetch = async (uri: string, options: any) => {
  //   return fetchAdapter.post(uri, options.body);
  // };

  // const link = new HttpLink({
  //   fetch: customFetch,
  //   uri: `${gqlHost}/_api/vod/public/graphql`,
  // });

  return new ApolloClient({
    uri: `${gqlHost}/_api/vod/public/graphql`,
    cache,
    headers: {
      Authorization: instance,
    },
    // link, // uncomment if you want requests going through the worker
  });
}
