import React, { FC } from 'react';
import { PlayerActionButton } from '../PlayerActionButton/PlayerActionButton';
import { UseVideoAccessButton } from '../../../hooks/useVideoAccessButton';
import PriceIcon from 'wix-ui-icons-common/on-stage/PriceFill';

export const VideoAccessActionButton: FC<{
  videoAccessButton: UseVideoAccessButton;
}> = ({ videoAccessButton }) => {
  const { text, handler } = videoAccessButton;
  return (
    <PlayerActionButton
      text={text}
      textShort={text}
      onClick={handler}
      icon={<PriceIcon />}
      alwaysExpanded
      dataHook="video-access-action-button"
    />
  );
};
