import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { IconButton } from 'wix-ui-tpa';
import ShareIcon from 'wix-ui-icons-common/on-stage/Share';
import { classes, st } from './ShareChannelButton.st.css';

export const ShareChannelButton: FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <IconButton
      icon={<ShareIcon />}
      className={st(classes.root)}
      onClick={onClick}
      aria-label={t('widget.accessibility.share')}
      data-hook="share-channel-button"
    />
  );
};
