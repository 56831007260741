import React, { FC, useCallback, useEffect } from 'react';
import {
  GqlVideoFragment,
  useGetChannelVideosQuery,
} from '../../apollo/generated/graphql';
import { useChannelId } from '../../hooks';
import { defaultFilter, useVideosFilter } from '../../hooks/useVideosFilter';
import { ChannelVideosContext } from '../../hooks/useChannelVideos';
import { NetworkStatus } from '@apollo/client';

export const ChannelVideosProvider: FC = ({ children }) => {
  const channelId = useChannelId();
  const { filter } = useVideosFilter();
  const { data, fetchMore, loading, refetch, networkStatus } =
    useGetChannelVideosQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        id: channelId,
        filter,
      },
    });
  const videosSearching = networkStatus === NetworkStatus.refetch;
  const videos: GqlVideoFragment[] = data?.channel?.videos.entities || [];
  const cursor = data?.channel?.videos.cursor;

  const loadMoreVideos = useCallback(async () => {
    if (cursor && !loading) {
      await fetchMore({
        variables: { cursor },
      });
    }
  }, [cursor, fetchMore, loading]);

  useEffect(() => {
    if (filter !== defaultFilter) {
      refetch({
        filter,
      });
    }
  }, [filter, refetch]);

  const value = {
    videos,
    loadMoreVideos,
    videosSearching,
  };

  // do not render children until videos loaded
  return networkStatus === NetworkStatus.loading ? null : (
    <ChannelVideosContext.Provider value={value}>
      {children}
    </ChannelVideosContext.Provider>
  );
};
