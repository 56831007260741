import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import HLSAdapter from 'playable/dist/src/adapters/hls';
import { PlaybackComponentProps } from '../../Player.types';
import {
  create,
  ENGINE_STATES,
  IPlayerInstance,
  registerPlaybackAdapter,
  VIDEO_EVENTS,
  UI_EVENTS,
} from 'playable';
import s from './Playable.scss';
import {
  PlayableFullScreenManager,
  usePlayableFullScreenManager,
} from '../../../PlayableFullScreenManager/PlayableFullScreenManager';

registerPlaybackAdapter(HLSAdapter);

export type IPlayable = Required<IPlayerInstance>;

type Props = PlaybackComponentProps & {
  player: IPlayable;
};

const useFullScreenState = (player: IPlayable) => {
  const { setFullScreen } = usePlayableFullScreenManager();
  const handleFullscreenChanged = useCallback(
    (isFullScreen: boolean) => {
      setFullScreen(isFullScreen);
    },
    [setFullScreen],
  );

  useEffect(() => {
    player.on(UI_EVENTS.FULL_SCREEN_STATE_CHANGED, handleFullscreenChanged);
    return () => {
      player.off(UI_EVENTS.FULL_SCREEN_STATE_CHANGED, handleFullscreenChanged);
    };
  }, [handleFullscreenChanged, player]);
};

const Player: FC<Props> = ({
  player,
  src,
  title,
  playing,
  onEnd,
  onPlay,
  onPause,
  onPlayableInit,
  muted,
  controlsHidden = false,
  loop = false,
}) => {
  useFullScreenState(player);

  const handlePlay = useCallback(() => {
    onPlay && onPlay();
  }, [onPlay]);

  const handleEnd = useCallback(() => {
    onEnd && onEnd();
  }, [onEnd]);

  const handlePause = useCallback(() => {
    onPause && onPause();
  }, [onPause]);

  const handleStateChanged = useCallback(
    ({ nextState }: { prevState: ENGINE_STATES; nextState: ENGINE_STATES }) => {
      switch (nextState) {
        case ENGINE_STATES.PLAYING:
          handlePlay();
          break;
        case ENGINE_STATES.PAUSED:
          handlePause();
          break;
        case ENGINE_STATES.ENDED:
          handleEnd();
          break;
      }
    },
    [handlePlay, handleEnd, handlePause],
  );

  useEffect(() => {
    player.on(VIDEO_EVENTS.STATE_CHANGED, handleStateChanged);
    onPlayableInit && onPlayableInit(player);
    return () => {
      player.off(VIDEO_EVENTS.STATE_CHANGED, handleStateChanged);
    };
  }, [player, handleStateChanged, onPlayableInit]);

  useLayoutEffect(() => player.setSrc(src), [player, src]);

  useLayoutEffect(() => {
    if (controlsHidden) {
      player.hideMainUI();
      player.hideOverlay();
    } else {
      player.showMainUI();
      player.showOverlay();
    }
  }, [player, controlsHidden]);

  useLayoutEffect(
    () => (muted ? player.mute() : player.unmute()),
    [player, muted],
  );

  useLayoutEffect(() => player.setLoop(loop), [player, loop]);

  useLayoutEffect(() => player.setTitle(title), [player, title]);

  useLayoutEffect(() => {
    playing ? player.play() : player.pause();
  }, [player, playing]);

  return null;
};

export const Playable: FC<PlaybackComponentProps> = ({
  playableChildren,
  ...props
}) => {
  const [player, setPlayer] = useState<IPlayable>();

  const setRef = useCallback((div: HTMLDivElement | null) => {
    if (div) {
      const instance = create({
        fillAllSpace: true,
      }) as IPlayable;

      instance.attachToElement(div);
      setPlayer(instance);
    }
  }, []);

  return (
    <PlayableFullScreenManager>
      <div ref={setRef} className={s.playable}>
        {player && playableChildren
          ? createPortal(playableChildren(player), player.getElement())
          : null}
      </div>
      {player ? <Player {...props} player={player} /> : null}
    </PlayableFullScreenManager>
  );
};
