import React, { FC, ReactHTML } from 'react';
import classNames from 'classnames';
import styles from './VideoInfoText.scss';

type TextTheme = 'default' | 'description' | 'rolesTitle' | 'roleName' | 'role';
type Size = 'large' | 'normal' | 'small';

export type VideoInfoTextProps = {
  children: React.ReactNode;
  textTheme?: TextTheme;
  size?: Size;
  tagName?: keyof ReactHTML;
};

const getThemeClassName = (textTheme: TextTheme) => {
  switch (textTheme) {
    case 'description': {
      return styles.descriptionText;
    }
    case 'rolesTitle': {
      return styles.rolesTitle;
    }
    case 'roleName': {
      return styles.roleName;
    }
    case 'role': {
      return styles.role;
    }
    case 'default':
    default:
      return styles.root;
  }
};

const getSizeClassName = (size?: Size) => {
  switch (size) {
    case 'large': {
      return styles.large;
    }
    case 'normal': {
      return styles.normal;
    }
    case 'small': {
      return styles.small;
    }
    default: {
      return undefined;
    }
  }
};

export const VideoInfoText: FC<VideoInfoTextProps> = ({
  children,
  textTheme = 'default',
  size,
  tagName,
}) => {
  const Wrapper = tagName ?? 'span';
  return (
    <Wrapper
      className={classNames(
        styles.videoInfoText,
        getThemeClassName(textTheme),
        getSizeClassName(size),
      )}
    >
      {children}
    </Wrapper>
  );
};
