import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { PlayerActionButton } from '../PlayerActionButton/PlayerActionButton';
import VideoInfoIcon from './VideoInfoIcon';
import { useShowVideoInfo } from '../PlayerOverlayProvider/PlayerOverlayProvider';

export const VideoInfoActionButton: FC = () => {
  const { t } = useTranslation();
  const showVideoInfo = useShowVideoInfo();

  return (
    <PlayerActionButton
      icon={<VideoInfoIcon />} // using custom info icon because the one from ui-tpa doesn't match, may consider changing later
      text={t('widget.action-button-labels.video-info-full')}
      textShort={t('widget.action-button-labels.video-info-short')}
      ariaLabel={t('widget.accessibility.show-video-info')}
      dataHook="video-info-action-button"
      onClick={showVideoInfo}
    />
  );
};
