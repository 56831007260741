import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import LinkIcon from 'wix-ui-icons-common/on-stage/Link';
import { ShareMode } from './ShareMode';
import { DarkShareSocialButton } from './DarkShareSocialButton';
import { useDirectShareUrl, useShareBi } from './useShare';

type OnLinkCopied = () => void;

const useShareLink = (mode: ShareMode, onLinkCopied: OnLinkCopied) => {
  const shareBi = useShareBi('link', mode);
  const shareUrl = useDirectShareUrl(mode);
  return async () => {
    await window.navigator.clipboard.writeText(shareUrl);
    shareBi();
    onLinkCopied();
  };
};

export const DarkShareLink: FC<{
  mode: ShareMode;
  onLinkCopied: OnLinkCopied;
}> = ({ mode, onLinkCopied }) => {
  const { t } = useTranslation();
  const shareLink = useShareLink(mode, onLinkCopied);

  return (
    <DarkShareSocialButton
      Icon={LinkIcon}
      onClick={shareLink}
      tooltipText={t('share-overlay.social.copy-link')}
      ariaLabel={t('widget.accessibility.get-link')}
      dataHook="dark-share-link"
    />
  );
};
