import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { registerModule } from 'playable';
import { IPlayable } from '../../../common/components/Player/players/Playable/Playable';
import CardsModule from '@wix/wix-vod-cards';
import {
  GqlVideoCardPosition,
  GqlVideoCardTargetExternalType,
  GqlVideoCardType,
  GqlVideoFragment,
} from '../../../apollo/generated/graphql';
import {
  VideoCards,
  CardData,
  Player,
  VIDEO_CARD_TYPES,
  VIDEO_CARDS_POSITIONS,
} from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/video-cards/video-cards';

type PlayerWithCardsModule = IPlayable & Player;

registerModule('cards', CardsModule);

const typeMap: Record<GqlVideoCardType, VIDEO_CARD_TYPES> = {
  [GqlVideoCardType.Channel]: VIDEO_CARD_TYPES.CHANNEL,
  [GqlVideoCardType.Custom]: VIDEO_CARD_TYPES.CUSTOM,
  [GqlVideoCardType.Product]: VIDEO_CARD_TYPES.PRODUCT,
  [GqlVideoCardType.RecentVideo]: VIDEO_CARD_TYPES.RECENT_VIDEO,
  [GqlVideoCardType.Social]: VIDEO_CARD_TYPES.SOCIAL,
  [GqlVideoCardType.Video]: VIDEO_CARD_TYPES.VIDEO,
};

const positionMap: Record<GqlVideoCardPosition, VIDEO_CARDS_POSITIONS> = {
  [GqlVideoCardPosition.BottomLeft]: VIDEO_CARDS_POSITIONS.BOTTOM_LEFT,
  [GqlVideoCardPosition.BottomRight]: VIDEO_CARDS_POSITIONS.BOTTOM_RIGHT,
  // these 2 are actually not used anymore
  [GqlVideoCardPosition.TopLeft]: VIDEO_CARDS_POSITIONS.BOTTOM_LEFT,
  [GqlVideoCardPosition.TopRight]: VIDEO_CARDS_POSITIONS.BOTTOM_RIGHT,
};

const networkMap: Record<GqlVideoCardTargetExternalType, CardData['network']> =
  {
    [GqlVideoCardTargetExternalType.Facebook]: 'facebook',
    [GqlVideoCardTargetExternalType.Youtube]: 'youtube',
    [GqlVideoCardTargetExternalType.Twitter]: 'twitter',
  };

const useLabels = () => {
  const { t } = useTranslation();

  return {
    facebookButtonTitle: t(
      'widget.accessibility.action-cards.facebook-like-button',
    ),
    youtubeButtonTitle: t(
      'widget.accessibility.action-cards.youtube-subscribe-button',
    ),
    twitterButtonTitle: t(
      'widget.accessibility.action-cards.twitter-follow-button',
    ),
  };
};

const useCardsData = (video: GqlVideoFragment) => {
  const cards = video.cards.entities;

  return cards.map<CardData>(
    ({
      id,
      showAt,
      hideAt,
      thumbnailUrl,
      title,
      buttonText,
      type,
      position,
      targetExternalType,
      targetExternalId,
      targetExternalUrl,
    }) => ({
      actionId: id,
      clientId: id,
      from: showAt,
      to: hideAt,
      thumbnailUrl,
      text: title,
      buttonLabel: buttonText,
      type: typeMap[type],
      position: positionMap[position],
      network: targetExternalType ? networkMap[targetExternalType] : undefined,
      id: targetExternalId,
      url: targetExternalUrl,
    }),
  );
};

export const ActionCards: FC<{
  player: IPlayable;
  video: GqlVideoFragment;
}> = ({ player, video }) => {
  const labels = useLabels();
  const cardsData = useCardsData(video);

  return (
    <VideoCards
      player={player as PlayerWithCardsModule}
      cardsData={cardsData}
      labels={labels}
    />
  );
};
