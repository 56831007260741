import React, { FC, createContext, useContext, useState } from 'react';

export type PlayerOverlayWindow = 'videoInfo' | 'shareVideo';

type PlayerOverlayContext = {
  currentOverlay: PlayerOverlayWindow | null;
  closeOverlay: () => void;
  openOverlay: (overlayWindow: PlayerOverlayWindow) => void;
};
const PlayerOverlayContext = createContext<PlayerOverlayContext>({
  currentOverlay: null,
  closeOverlay: () => {},
  openOverlay: () => {},
});

const useOverlayProvider = (): PlayerOverlayContext => {
  const [overlayWindow, setOverlayWindow] =
    useState<PlayerOverlayWindow | null>(null);
  return {
    currentOverlay: overlayWindow,
    closeOverlay: () => {
      setOverlayWindow(null);
    },
    openOverlay: (overlayWindow) => {
      setOverlayWindow(overlayWindow);
    },
  };
};

const usePlayerOverlay = () => {
  return useContext(PlayerOverlayContext);
};

export const useCurrentPlayerOverlay = () => {
  const { currentOverlay } = usePlayerOverlay();
  return { currentOverlay };
};

export const usePlayerOverlayActions = () => {
  const { openOverlay, closeOverlay } = usePlayerOverlay();
  return { openOverlay, closeOverlay };
};

export const useShowVideoInfo = () => {
  const { openOverlay } = usePlayerOverlayActions();
  return () => openOverlay('videoInfo');
};

export const useShowShareVideo = () => {
  const { openOverlay } = usePlayerOverlayActions();
  return () => openOverlay('shareVideo');
};

export const PlayerOverlayProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const overlayContextValue = useOverlayProvider();
  return (
    <PlayerOverlayContext.Provider value={overlayContextValue}>
      {children}
    </PlayerOverlayContext.Provider>
  );
};
