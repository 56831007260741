import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';
import { VideoAspectRatio } from '../../../../common/components/VideoAspectRatio/VideoAspectRatio';
import s from './ComingSoon.scss';

export const ComingSoon: FC = () => {
  const { t } = useTranslation();

  return (
    <VideoAspectRatio>
      <div className={s.root}>
        <span>{t('widget.this-channel-is-coming-soon')}</span>
      </div>
    </VideoAspectRatio>
  );
};
