import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import ShareIcon from 'wix-ui-icons-common/on-stage/Share';
import { PlayerActionButton } from '../PlayerActionButton/PlayerActionButton';
import { useShowShareVideo } from '../PlayerOverlayProvider/PlayerOverlayProvider';

export const ShareVideoActionButton: FC = () => {
  const { t } = useTranslation();
  const shareVideo = useShowShareVideo();

  return (
    <PlayerActionButton
      icon={<ShareIcon />} // using custom info icon because the one from ui-tpa doesn't match, may consider changing later
      text={t('widget.action-button-labels.share-video-full')}
      textShort={t('widget.action-button-labels.share-video-short')}
      ariaLabel={t('widget.accessibility.share')}
      dataHook="video-share-action-button"
      onClick={shareVideo}
    />
  );
};
