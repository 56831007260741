import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Animate } from '../Animate/Animate';
import {
  PlayerOverlayWindow,
  useCurrentPlayerOverlay,
  usePlayerOverlayActions,
} from '../PlayerOverlayProvider/PlayerOverlayProvider';
import { PlayerOverlayVideoInfo } from '../PlayerOverlayVideoInfo/PlayerOverlayVideoInfo';
import { useSelectedVideo } from '../../../hooks';
import { usePlayableFullScreenManager } from '../../../common/components/PlayableFullScreenManager/PlayableFullScreenManager';
import { DarkOverlayWrapper } from '../DarkOverlayWrapper/DarkOverlayWrapper';
import { DarkShareOverlay } from '../DarkShareOverlay/DarkShareOverlay';
import s from './PlayerOverlay.scss';

const PlayerShareVideo: FC = () => {
  const { closeOverlay } = usePlayerOverlayActions();
  return <DarkShareOverlay mode="video" closeOverlay={closeOverlay} />;
};

const PlayerOverlayContent: FC<{
  currentOverlay: PlayerOverlayWindow;
  closeOverlay: () => void;
}> = ({ currentOverlay, closeOverlay }) => {
  const { t } = useTranslation();
  switch (currentOverlay) {
    case 'videoInfo': {
      return (
        <PlayerOverlayWrapper
          ariaLabel={t('widget.accessibility.video-info')}
          closeButtonAriaLabel={t('widget.accessibility.close-video-info')}
          closeOverlay={closeOverlay}
        >
          <PlayerOverlayVideoInfo />
        </PlayerOverlayWrapper>
      );
    }
    case 'shareVideo': {
      return <PlayerShareVideo />;
    }
    default: {
      return null;
    }
  }
};

const useHasPoster = () => {
  const { selectedVideo } = useSelectedVideo();
  return Boolean(selectedVideo.poster.selected);
};

const PlayerOverlayWrapper: FC<{
  children: React.ReactNode;
  ariaLabel: string;
  closeButtonAriaLabel: string;
  closeOverlay: () => void;
}> = ({ children, ariaLabel, closeButtonAriaLabel, closeOverlay }) => {
  const hasPoster = useHasPoster();
  const { isFullScreen } = usePlayableFullScreenManager();
  const shouldShowHalf = !hasPoster;

  return (
    <DarkOverlayWrapper
      ariaLabel={ariaLabel}
      closeButtonAriaLabel={closeButtonAriaLabel}
      isFullScreen={isFullScreen}
      shouldShowHalf={shouldShowHalf}
      closeOverlay={closeOverlay}
    >
      {children}
    </DarkOverlayWrapper>
  );
};

const usePlayerOverlayState = () => {
  const { currentOverlay } = useCurrentPlayerOverlay();
  const { closeOverlay } = usePlayerOverlayActions();
  const [overlayShown, setOverlayShown] = useState(false);
  useEffect(() => {
    if (currentOverlay) {
      setOverlayShown(true);
    }
  }, [currentOverlay, setOverlayShown]);

  const onAnimationEnd = (active: boolean) => {
    if (!active) {
      closeOverlay();
    }
  };

  return {
    overlayShown,
    closeOverlay: () => {
      setOverlayShown(false);
    },
    currentOverlay,
    onAnimationEnd,
  };
};

export const PlayerOverlay: FC = () => {
  const { closeOverlay, overlayShown, currentOverlay, onAnimationEnd } =
    usePlayerOverlayState();

  return (
    <Animate
      type="fade-in-out"
      open={overlayShown}
      shownClassName={s.playerOverlayShownAnimate}
      onAnimationEnd={onAnimationEnd}
    >
      {() =>
        currentOverlay ? (
          <PlayerOverlayContent
            currentOverlay={currentOverlay}
            closeOverlay={closeOverlay}
          />
        ) : null
      }
    </Animate>
  );
};
