import React, { FC, useMemo } from 'react';
import { GqlVideoFragment } from '../../../apollo/generated/graphql';
import { Player } from '../../../common/components/Player/Player';
import s from './DesktopPlayer.scss';
import { IPlayable } from '../../../common/components/Player/players/Playable/Playable';
import { useResizeDetector } from 'react-resize-detector';
import { ActionCards } from '../ActionCards/ActionCards';
import { VideoInfoActionButton } from '../VideoInfoActionButton/VideoInfoActionButton';
import { ActionsPanel } from '../ActionsPanel/ActionsPanel';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../stylesParams';
import { PlayerOverlay } from '../PlayerOverlay/PlayerOverlay';
import { useVideoAccessButton } from '../../../hooks';
import { VideoAccessActionButton } from '../VideoAccessActionButton/VideoAccessActionButton';
import { ShareVideoActionButton } from '../ShareVideoActionButton/ShareVideoActionButton';
import { VideoEndHandler } from '../VideoEndHandler/VideoEndHandler';

type Props = {
  video: GqlVideoFragment;
};

const minWidth = 579;
const maxWidth = 300;

// these attributes are only needed for @wix/wix-vod-cards
const useAttributesForVideoCards = () => {
  const { width, ref } = useResizeDetector();

  const attributes = useMemo(() => {
    if (width === undefined) {
      return {};
    }

    if (width >= minWidth) {
      return {
        'data-vod-player-min-width': `${minWidth}px`,
      };
    }

    if (width <= maxWidth) {
      return {
        'data-vod-player-max-width': `${maxWidth}px`,
      };
    }

    return {};
  }, [width]);

  return {
    ref,
    attributes,
  };
};

const useViewSettings = () => {
  const styles = useStyles();
  return {
    showVideoInfo: styles.get(stylesParams.showFullInfo),
    showVideoShare: styles.get(stylesParams.enableVideosSharing),
  };
};

const DesktopPlayerExtras: FC<{
  player: IPlayable;
  video: GqlVideoFragment;
}> = ({ player, video }) => {
  const { showVideoInfo, showVideoShare } = useViewSettings();
  const videoAccessButton = useVideoAccessButton(video);

  return (
    <>
      <ActionCards player={player} video={video} />
      <ActionsPanel>
        {videoAccessButton ? (
          <VideoAccessActionButton videoAccessButton={videoAccessButton} />
        ) : null}
        {showVideoInfo ? <VideoInfoActionButton /> : null}
        {showVideoShare ? <ShareVideoActionButton /> : null}
      </ActionsPanel>
      <PlayerOverlay />
    </>
  );
};

export const DesktopPlayer: FC<Props> = ({ video }) => {
  const { ref, attributes } = useAttributesForVideoCards();

  return (
    <div className={s.desktopPlayer} ref={ref} {...attributes}>
      <VideoEndHandler>
        {(handleEnd) => (
          <Player
            video={video}
            playableChildren={(player: IPlayable) => (
              <DesktopPlayerExtras player={player} video={video} />
            )}
            onEnd={handleEnd}
          />
        )}
      </VideoEndHandler>
    </div>
  );
};
