import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  SHOW_INFO_VALUES,
  INFO_POSITION_VALUES,
} from '@wix/wix-vod-constants/app-settings';
import { useStyles } from '@wix/tpa-settings/react';
import { VideoAspectRatio } from '../../../../../common/components/VideoAspectRatio/VideoAspectRatio';
import { GqlVideoFragment } from '../../../../../apollo/generated/graphql';
import s from './Thumbnail.scss';
import {
  usePlayback,
  useSelectedVideo,
  useVideoPricing,
  useVideoSource,
} from '../../../../../hooks';
import { ThumbnailPlayButton } from '../../../../../common/components/ThumbnailPlayButton/ThumbnailPlayButton';
import { useDuration } from '../../../../../hooks/useDuration';

import stylesParams from '../../../../../../../stylesParams';
import { useVideoPlayButton } from '../../../../../hooks/useVideoPlayButton';
import CalendarSmallIcon from 'wix-ui-icons-common/on-stage/CalendarSmall';
import { LiveLabel } from '../../../../../common/components/LiveLabel/LiveLabel';
import {
  Picture,
  PictureMode,
} from '../../../../../common/components/Picture/Picture';
import { CurrencySign } from '../../../../../common/components/CurrencySign/CurrencySign';
import { Currency } from '../../../../../common/components/CurrencySign/Currencies';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useEnterOrSpaceKeyDown } from '../../../../../hooks/useEnterOrSpaceKeyDown';

type Props = {
  video: GqlVideoFragment;
};

const useViewSettings = () => {
  const styles = useStyles();
  return {
    showTitle: styles.get(stylesParams.showVideoTitleInList),
    videoInfoPosition: styles.get(stylesParams.videoInfoPosition),
  };
};

const LeftCorner: FC<Props> = ({ video }) => {
  const { isStreamingLive, isScheduledLive } = useVideoSource(video);
  const duration = useDuration(video.content?.duration);

  if (isStreamingLive) {
    return <LiveLabel size={12} />;
  }

  if (isScheduledLive) {
    return <CalendarSmallIcon />;
  }

  return <span>{duration}</span>;
};

export const Thumbnail: FC<Props> = ({ video }) => {
  const { selectVideo, selectedVideo } = useSelectedVideo();
  const { playing } = usePlayback();
  const { canPlay } = useVideoPlayButton(video);
  const styles = useStyles();
  const { currency } = useVideoPricing(video);
  const { showTitle, videoInfoPosition } = useViewSettings();
  const src = video.cover.selected;
  const { isStreamingLive } = useVideoSource(video);
  const { t } = useTranslation();

  const showInfoAction = useMemo(
    () => styles.get(stylesParams.showInfoAction),
    [styles],
  );

  const isVideoSelected = selectedVideo.id === video.id;
  const isVideoPlaying = playing && isVideoSelected;

  const handleOverlayClick = useCallback(() => {
    selectVideo(video);
  }, [selectVideo, video]);

  const handleKeydown = useEnterOrSpaceKeyDown(handleOverlayClick);

  return (
    <div
      className={s.root}
      onClick={handleOverlayClick}
      role="button"
      onKeyDown={handleKeydown}
      tabIndex={0}
      aria-label={video.title}
      data-hook="classic-video-thumbnail"
    >
      <VideoAspectRatio>
        {src ? (
          <Picture src={src} mode={PictureMode.CONTAIN} />
        ) : (
          <div className={s.stubPicture} />
        )}
        <div
          className={classNames(s.overlay, {
            [s.showOverlayOnHover]:
              showInfoAction === SHOW_INFO_VALUES.ON_MOUSE_OVER &&
              !isVideoSelected,
            [s.live]: isStreamingLive,
            [s.playing]: isVideoPlaying,
          })}
        >
          {isVideoPlaying ? (
            <div
              className={s.center}
              data-hook="classic-video-playing-status-text"
            >
              {t('thumbnail.now-playing')}
            </div>
          ) : (
            <>
              {showTitle &&
              videoInfoPosition === INFO_POSITION_VALUES.ON_VIDEOS ? (
                <header className={s.headerContainer}>
                  <h3 className={s.header}>{video.title}</h3>
                </header>
              ) : null}
              {canPlay && (
                <div className={s.center}>
                  <ThumbnailPlayButton video={video} />
                </div>
              )}
              <span className={s.leftCorner}>
                <LeftCorner video={video} />
              </span>
              {currency && (
                <span className={s.currency}>
                  <CurrencySign currency={currency as Currency} />
                </span>
              )}
            </>
          )}
        </div>
      </VideoAspectRatio>
      {showTitle && videoInfoPosition === INFO_POSITION_VALUES.BELOW_VIDEOS ? (
        <h3 className={s.videoTitleBelowThumb}>{video.title}</h3>
      ) : null}
    </div>
  );
};
