import React, { FC } from 'react';
import { EditorFlowErrorRuntimeErrorContext } from '@wix/yoshi-flow-editor';
import { Card, Text, TextTypography } from 'wix-ui-tpa';
import ErrorFill from 'wix-ui-icons-common/on-stage/ErrorFillSmall';
import s from './ClassicErrorState.scss';

export const ClassicErrorState: FC<EditorFlowErrorRuntimeErrorContext> = ({
  error,
  errorId,
  errorEnvironment,
}) => {
  console.error(error);
  console.error('error id', errorId, errorEnvironment);

  // Actual content should be here instead, change the texts etc when design is ready*/
  return (
    <Card>
      <Card.Container>
        <div className={s.iconWrapper}>
          <ErrorFill size={48} className={s.errorFillIcon} />
        </div>
      </Card.Container>
      <Card.Container>
        <Text typography={TextTypography.largeTitle} tagName="h3">
          Oops!
        </Text>
        <Text tagName="p">
          Something went wrong. Try to refresh the page. If the issue continues
          please let us know.
        </Text>
      </Card.Container>
    </Card>
  );
};
