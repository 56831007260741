import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react';

type PlayableFullScreenManager = {
  isFullScreen: boolean;
  setFullScreen(isFullScreen: boolean): void;
};

const FullScreenContext = createContext<PlayableFullScreenManager>({
  isFullScreen: false,
  setFullScreen() {},
});

export const usePlayableFullScreenManager = () => {
  return useContext(FullScreenContext);
};

export const PlayableFullScreenManager: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  return (
    <FullScreenContext.Provider value={{ isFullScreen, setFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  );
};
