import { useCallback, KeyboardEvent } from 'react';

export function useEnterOrSpaceKeyDown(callback: () => void) {
  return useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        callback();
      }
    },
    [callback],
  );
}
