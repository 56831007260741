import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import s from './LiveLabel.scss';

type Props = {
  size?: number;
};

export const LiveLabel: FC<Props> = ({ size = 15 }) => {
  const { t } = useTranslation();

  return (
    <div
      className={s.root}
      style={{
        fontSize: size,
      }}
    >
      {t('shared.live')}
    </div>
  );
};
