import React, { FC, useCallback, useState } from 'react';
import { GqlVideoFragment } from '../../apollo/generated/graphql';
import { useChannelVideos } from '../../hooks/useChannelVideos';
import {
  SelectedVideoContext,
  SelectedVideoContextType,
} from '../../hooks/useSelectedVideo';
import { usePlayback, useSharedVideo } from '../../hooks';

export const SelectedVideoProvider: FC = ({ children }) => {
  const { videos } = useChannelVideos();
  const { setInitial } = usePlayback();
  const sharedVideo = useSharedVideo();

  const [selectedVideo, setSelectedVideo] = useState<GqlVideoFragment>(
    sharedVideo || videos[0],
  );
  const [selectedVideoChanged, setSelectedVideoChanged] = useState(
    !!sharedVideo,
  );

  const selectVideo = useCallback(
    (video: GqlVideoFragment) => {
      if (video.id !== selectedVideo.id) {
        setSelectedVideo(video);
      }

      setInitial();
      setSelectedVideoChanged(true);
    },
    [setSelectedVideoChanged, setSelectedVideo, setInitial, selectedVideo],
  );

  const currentIndex = videos.findIndex(({ id }) => id === selectedVideo.id);
  const nextVideo =
    currentIndex !== videos.length - 1 ? videos[currentIndex + 1] : null;

  const value: SelectedVideoContextType = {
    selectedVideo,
    selectVideo,
    selectedVideoChanged,
    nextVideo,
  };

  return (
    <SelectedVideoContext.Provider value={value}>
      {children}
    </SelectedVideoContext.Provider>
  );
};
