import React, { FC } from 'react';
import { VideosFilterProvider } from '../../providers/VideosFilterProvider';
import { ChannelProvider } from '../../providers/ChannelProvider';
import { ChannelVideosProvider } from '../../providers/ChannelVideosProvider';

export const Channel: FC = ({ children }) => {
  return (
    <VideosFilterProvider>
      <ChannelProvider>
        <ChannelVideosProvider>{children}</ChannelVideosProvider>
      </ChannelProvider>
    </VideosFilterProvider>
  );
};
