import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import CheckIcon from 'wix-ui-icons-common/on-stage/Check';
import stylesParams from '../../../../../stylesParams';
import {
  DarkOverlayWrapper,
  DarkOverlayWrapperProps,
} from '../DarkOverlayWrapper/DarkOverlayWrapper';
import { DarkShareSelectMode } from './DarkShareSelectMode';
import { ShareMode } from './ShareMode';
import { DarkShareFacebook } from './DarkShareFacebook';
import { DarkShareTwitter } from './DarkShareTwitter';
import { DarkSharePinterest } from './DarkSharePinterest';
import { DarkShareTumblr } from './DarkShareTumblr';
import { DarkShareLink } from './DarkShareLink';
import s from './DarkShareOverlay.scss';

const useShareSettings = () => {
  const styles = useStyles();
  const canShareChannel = styles.get(stylesParams.showChannelShare);
  const canShareVideo = styles.get(stylesParams.enableVideosSharing);
  return { canShareChannel, canShareVideo };
};

const ShareTitleText: FC<{ mode: ShareMode }> = ({ mode }) => {
  const { t } = useTranslation();

  const { canShareVideo, canShareChannel } = useShareSettings();
  if (canShareChannel && canShareVideo) {
    return t('share-overlay.social.title');
  }
  switch (mode) {
    case 'channel': {
      return t('widget.compact-view.components.action-bar.share-this-channel');
    }
    case 'video': {
      return t('share-overlay.social.share');
    }
  }
};

const ShareSocialButtons: FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className={s.socialButtons}>{children}</div>;

const useLinkCopied = () => {
  const [isShowingLinkCopied, setIsShowingLinkCopied] = useState(false);
  useEffect(() => {
    if (!isShowingLinkCopied) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsShowingLinkCopied(false);
    }, 3000);
    return () => window.clearTimeout(timeoutId);
  }, [isShowingLinkCopied]);
  return {
    isShowingLinkCopied,
    showLinkCopied: () => setIsShowingLinkCopied(true),
  };
};

const LinkCopied: FC<{ shown: boolean }> = ({ shown }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(s.linkCopied, { [s.shown]: shown })}
      data-hook="link-copied"
    >
      <CheckIcon aria-hidden />
      <span>{t('share-overlay.social.link-copied')}</span>
    </div>
  );
};

const ShareOverlayContent: FC<{ initialMode: ShareMode }> = ({
  initialMode,
}) => {
  const [mode, setMode] = useState<ShareMode>(initialMode);
  const { canShareVideo, canShareChannel } = useShareSettings();
  const { isShowingLinkCopied, showLinkCopied } = useLinkCopied();
  const canShowSelect = canShareVideo && canShareChannel;

  return (
    <section className={s.shareOverlayContainer}>
      <h3 className={s.shareTitle}>
        <ShareTitleText mode={mode} />
      </h3>
      {canShowSelect ? (
        <div className={s.modeSelect}>
          <DarkShareSelectMode mode={mode} onChangeMode={setMode} />
        </div>
      ) : null}
      <ShareSocialButtons>
        <DarkShareFacebook mode={mode} />
        <DarkShareTwitter mode={mode} />
        <DarkSharePinterest mode={mode} />
        <DarkShareTumblr mode={mode} />
        <DarkShareLink mode={mode} onLinkCopied={showLinkCopied} />
      </ShareSocialButtons>
      <LinkCopied shown={isShowingLinkCopied} />
    </section>
  );
};
export const DarkShareOverlay: FC<
  { mode: ShareMode } & Omit<
    DarkOverlayWrapperProps,
    'ariaLabel' | 'closeButtonAriaLabel' | 'children'
  >
> = ({ mode, ...props }) => {
  const { t } = useTranslation();
  return (
    <DarkOverlayWrapper
      ariaLabel={t('widget.accessibility.share')}
      closeButtonAriaLabel={t('widget.accessibility.close-share')}
      {...props}
    >
      <ShareOverlayContent initialMode={mode} />
    </DarkOverlayWrapper>
  );
};
