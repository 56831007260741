import React, { FC, useEffect } from 'react';
import { useContainerQuery } from 'react-container-query';
import classNames from 'classnames';
import styles from './DarkOverlayWrapper.scss';
import { Query } from 'react-container-query/lib/interfaces';
import { DarkOverlayCloseButton } from './DarkOverlayCloseButton';
import { FocusLock } from '../../../common/components/FocusLock/FocusLock';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../stylesParams';

const useCloseOnEsc = (closeOverlay: () => void) => {
  useEffect(() => {
    const escHandler = (event: KeyboardEvent) => {
      // Esc for IE/edge, Escape for others
      if (event.key === 'Esc' || event.key === 'Escape') {
        closeOverlay();
      }
    };

    document.addEventListener('keyup', escHandler);

    return () => {
      document.removeEventListener('keyup', escHandler);
    };
  }, [closeOverlay]);
};

const CONTAINER_QUERY: Query = {
  [styles.minWidth250px]: {
    minWidth: 250,
    maxWidth: 499,
  },
  [styles.minWidth500px]: {
    minWidth: 500,
    maxWidth: 979,
  },
  [styles.minWidth980px]: {
    minWidth: 980,
  },
};

export type DarkOverlayWrapperProps = {
  children: React.ReactNode;
  ariaLabel: string;
  closeButtonAriaLabel: string;
  shouldShowHalf?: boolean;
  isFullScreen?: boolean;
  closeOverlay: () => void;
};

const useFontStyle = (): Record<string, string> | undefined => {
  const styles = useStyles();
  const textFont = styles.get(stylesParams.textFont);
  const cssFontFamily = textFont.family || textFont.cssFontFamily;
  return cssFontFamily
    ? {
        '--darkOverlayFontFamily': cssFontFamily,
      }
    : undefined;
};

export const DarkOverlayWrapper: FC<DarkOverlayWrapperProps> = ({
  children,
  ariaLabel,
  closeButtonAriaLabel,
  shouldShowHalf = false,
  isFullScreen = false,
  closeOverlay,
}) => {
  useCloseOnEsc(closeOverlay);
  const fontStyle = useFontStyle();
  // the initial size is required by types, but not really used after all, so passing
  // the zeroes.
  const [queryClasses, ref] = useContainerQuery(CONTAINER_QUERY, {
    width: 0,
    height: 0,
  });

  // {/* todo: get rid of container query in favor of useResizeDetector() in */}
  // {/* DesktopPlayer and pass around a single // width object for the components */}
  // {/* to build their "element queries" */}
  return (
    <FocusLock className={classNames(styles.overlayContainer, queryClasses)}>
      <div
        className={classNames(styles.overlayContent, {
          [styles.half]: shouldShowHalf,
          [styles.fullScreen]: isFullScreen,
        })}
        role="dialog"
        aria-label={ariaLabel}
        data-hook="dark-overlay-wrapper"
        style={fontStyle}
        ref={ref}
      >
        {children}
      </div>
      <div className={styles.closeButtonContainer}>
        <DarkOverlayCloseButton
          ariaLabel={closeButtonAriaLabel}
          closeOverlay={closeOverlay}
        />
      </div>
    </FocusLock>
  );
};
