import React, {
  KeyboardEvent,
  ChangeEvent,
  FC,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import s from './Search.scss';
import SearchIcon from 'wix-ui-icons-common/Search';
import DismissIcon from 'wix-ui-icons-common/Dismiss';
import ArrowRightIcon from 'wix-ui-icons-common/ArrowRight';
import { useVideosFilter } from '../../../../hooks/useVideosFilter';
import { Tags } from './Tags/Tags';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../stylesParams';

const useViewSettings = () => {
  const styles = useStyles();
  const showTags = styles.get(stylesParams.showChannelTags);
  return { showTags };
};

export const Search: FC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { filter, setQuery, clearFilter } = useVideosFilter();
  const { showTags } = useViewSettings();
  const [value, setValue] = useState<string>('');
  const isChanged = useMemo(
    () => value !== (filter.query || filter.tag),
    [filter, value],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && isChanged) {
        setQuery(value);
        event.currentTarget.blur();
      }
    },
    [isChanged, setQuery, value],
  );

  const handleEscDown = useCallback((event: KeyboardEvent<HTMLElement>) => {
    if (event.target && (event.key === 'Esc' || event.key === 'Escape')) {
      (event.target as HTMLElement).blur();
    }
  }, []);

  const handleClearClick = useCallback(() => {
    setValue('');
    clearFilter();
  }, [setValue, clearFilter]);

  const handleArrowClick = useCallback(
    () => setQuery(value),
    [value, setQuery],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  useEffect(() => {
    if (filter.category) {
      setValue('');
    }

    if (filter.tag) {
      setValue(filter.tag);
    }
  }, [filter]);

  return (
    <div className={s.root} ref={ref} onKeyDown={handleEscDown}>
      <div className={s.searchBar}>
        <SearchIcon className={classNames(s.icon, s.searchIcon)} />
        <input
          type="text"
          className={s.input}
          placeholder={t('widget.search-bar.search-video')}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={value}
        />
        {isChanged && (
          <button onClick={handleArrowClick} className={s.rightIcon}>
            <ArrowRightIcon />
          </button>
        )}
        {!isChanged && Boolean(value) && (
          <button onClick={handleClearClick} className={s.rightIcon}>
            <DismissIcon />
          </button>
        )}
      </div>
      {showTags ? (
        <div className={s.tags}>
          <Tags />
        </div>
      ) : null}
    </div>
  );
};
