import React, { FC } from 'react';
import { IconButton } from 'wix-ui-tpa';
import { classes } from './ThumbnailButton.st.css';

type Props = {
  Icon: FC<React.SVGAttributes<SVGElement>>;
  onClick?: React.MouseEventHandler;
  iconSize?: number;
  text?: string;
};

export const ThumbnailButton: FC<Props> = ({
  Icon,
  onClick,
  iconSize = 40,
  text,
}) => {
  return (
    <IconButton
      className={classes.root}
      onClick={onClick}
      icon={<Icon width={`${iconSize}px`} height={`${iconSize}px`} />}
    >
      {text && <span>{text}</span>}
    </IconButton>
  );
};
