import React, { FC, useCallback } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import classNames from 'classnames';
import s from './Player.scss';
import { PlaybackComponentProps } from './Player.types';
import {
  GqlVideoFragment,
  GqlVideoSource,
} from '../../../apollo/generated/graphql';
import { IPlayable, Playable } from './players/Playable/Playable';
import { usePlayback } from '../../../hooks';
import { ReactPlayer } from './players/ReactPlayer';
import { Vimeo } from './players/Vimeo/Vimeo';
import { Facebook } from './players/Facebook/Facebook';
import stylesParams from '../../../../../stylesParams';

const PlaybackComponents: {
  [key in GqlVideoSource]: FC<PlaybackComponentProps>;
} = {
  [GqlVideoSource.Computer]: Playable,
  [GqlVideoSource.Dropbox]: Playable,
  [GqlVideoSource.GoogleDrive]: Playable,
  [GqlVideoSource.Live]: Playable,
  [GqlVideoSource.Facebook]: Facebook,
  [GqlVideoSource.Vimeo]: Vimeo,
  [GqlVideoSource.Youtube]: ReactPlayer,
};

type Props = {
  video: GqlVideoFragment;
  playableChildren: (player: IPlayable) => React.ReactNode;
  onEnd?(): void;
};

const useViewSettings = () => {
  const styles = useStyles();
  return { showTitle: styles.get(stylesParams.showVideoTitle) };
};

export const Player: FC<Props> = ({ video, playableChildren, onEnd }) => {
  const {
    source,
    title,
    play: {
      entity: { url },
    },
  } = video;
  const { pause, play, playing, paused, muted, setEnded } = usePlayback();
  const { showTitle } = useViewSettings();

  const PlaybackComponent = PlaybackComponents[source];

  const handlePlay = useCallback(() => {
    if (!playing) {
      play();
    }
  }, [playing, play]);

  const handleEnd = useCallback(() => {
    setEnded();
    onEnd && onEnd();
  }, [setEnded, onEnd]);

  return url ? (
    <div
      className={classNames(s.root, {
        [s.visible]: playing || paused,
      })}
    >
      <PlaybackComponent
        playableChildren={playableChildren}
        src={url}
        title={showTitle ? title : undefined}
        playing={playing}
        onPlay={handlePlay}
        onPause={pause}
        onEnd={handleEnd}
        muted={muted}
      />
    </div>
  ) : null;
};
