import classnames from 'classnames';
import { ResizeSensor } from 'css-element-queries';
import { ORIENTATIONS, DIRECTIONS, EVENTS } from '../constants';
import styles from './card.scss';
const positionProperties = {
  [ORIENTATIONS.HORIZONTAL]: {
    [DIRECTIONS.STANDARD]: 'left',
    [DIRECTIONS.REVERSE]: 'right'
  },
  [ORIENTATIONS.VERTICAL]: {
    [DIRECTIONS.STANDARD]: 'top',
    [DIRECTIONS.REVERSE]: 'bottom'
  }
};
export default class Card {
  constructor(_ref) {
    let eventEmitter = _ref.eventEmitter,
        cardsConfig = _ref.cardsConfig,
        contentNode = _ref.contentNode,
        from = _ref.from,
        to = _ref.to,
        order = _ref.order,
        id = _ref.id,
        _ref$hasDynamicConten = _ref.hasDynamicContent,
        hasDynamicContent = _ref$hasDynamicConten === void 0 ? false : _ref$hasDynamicConten;
    this.eventEmitter = eventEmitter;
    this.cardsConfig = cardsConfig;
    this.contentNode = contentNode;
    this.isActive = false;
    this.isVisible = false;
    this.isClosed = false;
    this.from = from / 1000;
    this.to = to / 1000;
    this.order = order;
    this.id = id; // for cards that can be resized after load and need ResizeSensor

    this.hasDynamicContent = hasDynamicContent;
    this.initContainer();
  }

  initContainer() {
    this.node = document.createElement('div');
    this.node.className = classnames(styles.container, styles.hidden, 'action-card');
    this.node.setAttribute('data-hook', 'action-card');
    const closeButton = document.createElement('div');
    closeButton.className = styles['close-button'];
    closeButton.setAttribute('data-hook', 'card-close-button'); // inlining it here, because since yoshi 6 there is no way to import inlined svg, only react component
    // TODO: replace this with the file once this is done: https://wix.slack.com/archives/CAL591CDV/p1654862399946799?thread_ts=1653399361.485889&cid=CAL591CDV

    closeButton.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\"><path fill=\"#FFF\" fill-rule=\"evenodd\" d=\"m5.94 5 3.77-3.77-.94-.94L5 4.06 1.23.29l-.94.94L4.06 5 .29 8.77l.94.94L5 5.94l3.77 3.77.94-.94L5.94 5z\"/></svg>";
    this.node.appendChild(this.contentNode);
    this.node.appendChild(closeButton);
    closeButton.addEventListener('click', this.close.bind(this));
  }

  show() {
    this.isVisible = true;
    this.node.classList.remove(styles.hidden);
    this.node.classList.add(styles.visible);
    this.eventEmitter.emit(EVENTS.CARD_SHOWN, this);
  }

  hide() {
    this.isVisible = false;
    this.node.classList.remove(styles.visible);
    this.node.classList.add(styles.hidden);
    this.eventEmitter.emit(EVENTS.CARD_HIDDEN, this);
  }

  close() {
    this.isClosed = true;
    this.eventEmitter.emit(EVENTS.CARD_CLOSED, this);
  }

  shouldBeActiveAt(time) {
    return !this.isClosed && time >= this.from && time <= this.to;
  }

  setInitialPosition(offset) {
    if (offset === void 0) {
      offset = 0;
    }

    this.node.style.left = 'auto';
    this.node.style.right = 'auto'; // set negative position to a card for animate its appearance from screen border

    this.updatePosition(-1 * (this.getSize() + offset));
  }

  getSize() {
    const orientation = this.cardsConfig.orientation;
    return orientation === ORIENTATIONS.HORIZONTAL ? this.node.offsetWidth : this.node.offsetHeight;
  }

  updatePosition(offset) {
    const _this$cardsConfig = this.cardsConfig,
          orientation = _this$cardsConfig.orientation,
          direction = _this$cardsConfig.direction;
    const positionProperty = positionProperties[orientation][direction];
    this.node.style[positionProperty] = offset + "px";
  }

  addResizeHandler(handler) {
    if (!this.resizeSensor && this.hasDynamicContent) {
      this.resizeSensor = new ResizeSensor(this.node, handler);
    }
  }

  removeResizeHandler(handler) {
    if (this.resizeSensor) {
      this.resizeSensor.detach(handler);
    }
  }

}