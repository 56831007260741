import React, { FC, useCallback, MouseEvent } from 'react';
import s from './Tags.scss';
import { useChannel } from '../../../../../hooks/useChannel';
import { useVideosFilter } from '../../../../../hooks/useVideosFilter';

export const Tags: FC = () => {
  const channel = useChannel();
  const { setTag } = useVideosFilter();

  const handleTagClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setTag(e.currentTarget.value);
      e.currentTarget.blur();
    },
    [setTag],
  );

  return (
    <ul className={s.root}>
      {channel.tags.map((tag) => (
        <li key={tag}>
          <button className={s.tag} value={tag} onClick={handleTagClick}>
            {tag}
          </button>
        </li>
      ))}
    </ul>
  );
};
