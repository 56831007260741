import React, { FC, useState } from 'react';
import { GqlVideosFilter } from '../../apollo/generated/graphql';
import {
  defaultFilter,
  VideosFilterContext,
} from '../../hooks/useVideosFilter';

export const VideosFilterProvider: FC = ({ children }) => {
  const [filter, setFilter] = useState<GqlVideosFilter>(defaultFilter);

  return (
    <VideosFilterContext.Provider value={{ filter, setFilter }}>
      {children}
    </VideosFilterContext.Provider>
  );
};
