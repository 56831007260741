/* eslint-disable */
/* tslint:disable */
import React from 'react';
export interface InfoIconProps extends React.SVGAttributes<SVGElement> {
  size?: string;
}
const InfoIcon: React.FC<InfoIconProps> = ({ size, ...props }) => (
  <svg
    viewBox="0 0 8 18"
    fill="currentColor"
    width={size || '8'}
    height={size || '18'}
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.5 0a2.501 2.501 0 0 1 0 5 2.5 2.5 0 0 1 0-5zm4.498 18H.276l.002-.557c.513-.093.877-.172 1.093-.24.216-.068.4-.164.552-.286.15-.12.27-.306.354-.557.084-.252.13-.643.13-1.175v-4.97c0-.487-.015-.878-.042-1.174-.026-.296-.117-.534-.275-.712-.159-.178-.4-.313-.721-.4A9.307 9.307 0 0 0 0 7.708v-.605L4.589 7H5.79v8.154c0 .654.052 1.107.156 1.362.105.254.305.447.602.581.294.133.778.249 1.447.348L8 18h-.002z"
      fillRule="evenodd"
    />
  </svg>
);
InfoIcon.displayName = 'InfoIcon';
export default InfoIcon;
/* tslint:enable */
/* eslint-enable */
