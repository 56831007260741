import React, { FC } from 'react';
import InfoIcon from 'wix-ui-icons-common/on-stage/Info';
import { IconButton } from 'wix-ui-tpa';
import { useWorker } from '../../../hooks/useWorker';
import { st, classes } from './InfoButton.st.css';

export const InfoButton: FC = () => {
  const {
    modals: { openDesktopChannelInfo },
  } = useWorker();

  return (
    <IconButton
      icon={<InfoIcon />}
      className={st(classes.root)}
      onClick={() => openDesktopChannelInfo()}
    />
  );
};
