import React, { FC, useCallback, useMemo } from 'react';
import { Dropdown, DropdownTheme, DropdownOptionProps } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from './Categories.st.css';
import { useChannel } from '../../../../hooks/useChannel';
import { useVideosFilter } from '../../../../hooks/useVideosFilter';

export const Categories: FC = () => {
  const { t } = useTranslation();
  const { setCategory, filter } = useVideosFilter();
  const channel = useChannel();

  const options = useMemo<DropdownOptionProps[]>(
    () => [
      {
        id: '',
        value: t('widget.categories.all-videos'),
      },
      ...channel.categories.map((category) => ({
        id: category,
        value: category,
      })),
    ],
    [channel, t],
  );

  const handleChange = useCallback(
    (option: DropdownOptionProps) => {
      setCategory(option.id || '');
    },
    [setCategory],
  );

  return (
    <div className={st(classes.root)}>
      <Dropdown
        key={filter.category} // rerender on filter change
        className={st(classes.dropdown)}
        theme={DropdownTheme.Line}
        placeholder={t('widget.categories.all-videos')}
        options={options}
        onChange={handleChange}
        initialSelectedId={filter.category}
      />
    </div>
  );
};
