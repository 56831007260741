import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const Direction: FC = ({ children }) => {
  const { isRTL } = useEnvironment();
  const dir = isRTL ? 'rtl' : 'ltr';

  return (
    <div data-hook="wix-vod-widget-direction-container" dir={dir}>
      {children}
    </div>
  );
};
