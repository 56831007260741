import React, { FC } from 'react';
import { IconButton, Tooltip } from 'wix-ui-tpa';
import { classes } from './DarkShareSocialButton.st.css';

type IconProps = React.SVGAttributes<SVGElement> & {
  size?: string | number;
};
type Props = {
  Icon: React.ComponentType<IconProps>;
  tooltipText: string;
  ariaLabel: string;
  onClick: () => void;
  dataHook?: string;
};

export const DarkShareSocialButton: FC<Props> = ({
  Icon,
  tooltipText,
  ariaLabel,
  onClick,
  dataHook,
}) => (
  <Tooltip content={tooltipText} appendTo="scrollParent">
    <IconButton
      className={classes.root}
      icon={<Icon size={30} />}
      onClick={onClick}
      aria-label={ariaLabel}
      data-hook={dataHook}
    />
  </Tooltip>
);
